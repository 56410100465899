import * as React from 'react';
import {Button, Form, Icon, Label} from "semantic-ui-react";
import {FormattedMessage, injectIntl} from "react-intl";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {selectLocaleForDatepicker} from "../../app/Parameters";
import {Database} from "../../app/AppDatabase";
import {NavigationView, withGlobalState} from "../../GlobalStateProvider";
import {bySurnameNameSorter} from "../../common/Helpers";

class EditPatientFormImpl extends React.Component {

    constructor(props) {
        super(props);

        let {patient} = this.props;
        this.state = {
            name: patient ? patient.name : "",
            surname: patient ? patient.surname : "",
            about: patient ? patient.about : "",
            birthDate: patient ? patient.birthDate : undefined,
            invalidForm: false
        };
    }

    handleChange = (_, {id, value}) => this.setState({[id]: value});

    handleSubmit = () => {
        const {name, surname, about, birthDate} = this.state;
        const {patient, state, dispatch} = this.props;
        const editing = patient !== undefined
        if (this.isValidForm()) {
            this.setState({
                invalidForm: false
            });
            const newPatient = {name, surname, about, birthDate};
            if (editing) {
                // Reuse existing id, we are only updating!
                newPatient["_id"] = patient._id;
                // Rewrite existing numbers of examinations/symmetries/differences
                newPatient.num_examinations = patient.num_examinations;
                newPatient.num_symmetries = patient.num_symmetries;
                newPatient.num_differences = patient.num_differences;
                Database.updatePatient(newPatient).then(() => {
                    const updatedPatients = state.patients.map(entry => entry._id === newPatient._id ? newPatient : entry);
                    dispatch({
                        activeView: NavigationView.PATIENT_DETAIL,
                        patients: updatedPatients
                    });
                });
            } else {
                Database.createPatient(newPatient).then(savedPatient => {
                    savedPatient.num_examinations = 0;
                    savedPatient.num_symmetries = 0;
                    savedPatient.num_differences = 0;
                    const updatedPatients = state.patients;
                    updatedPatients.push(savedPatient);
                    dispatch({
                        activeView: NavigationView.PATIENTS_LIST,
                        patients: updatedPatients.sort(bySurnameNameSorter)
                    });
                }, errors => console.error(errors));
            }
        } else {
            this.setState({
                invalidForm: true
            });
        }
    }

    isValidForm = () => {
        const {name, surname, birthDate} = this.state;
        return name.length > 0 && surname.length > 0 && birthDate;
    }

    render() {
        const {name, surname, about, birthDate, invalidForm} = this.state;
        const {intl, patient, state, dispatch} = this.props;
        const editing = patient !== undefined
        const firstNameLabel = intl.formatMessage({id: "patients.form.first_name"});
        const surnameLabel = intl.formatMessage({id: "patients.form.surname"});
        const birthDateFieldError = invalidForm && !birthDate;
        return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        label={firstNameLabel}
                        id='name'
                        placeholder={firstNameLabel}
                        value={name}
                        onChange={this.handleChange}
                        fluid
                        required
                        error={invalidForm && name.length === 0
                            ? intl.formatMessage({id: "patients.form.first_name.error"})
                            : false}/>
                    <Form.Input
                        label={surnameLabel}
                        id='surname'
                        placeholder={surnameLabel}
                        value={surname}
                        onChange={this.handleChange}
                        fluid
                        required
                        error={invalidForm && surname.length === 0
                            ? intl.formatMessage({id: "patients.form.surname.error"})
                            : false}/>
                    <Form.Field error={birthDateFieldError}>
                        <div className={"fullWidthSemanticDatepicker"}>
                            <SemanticDatepicker
                                label={intl.formatMessage({
                                    id: "patients.form.birthdate",
                                    description: "Date of birth"
                                })}
                                id="initialDate"
                                value={birthDate !== undefined ? new Date(birthDate) : undefined}
                                locale={selectLocaleForDatepicker(state.settings)}
                                showToday={false}
                                onChange={(e, {value}) => this.setState({birthDate: value})}
                                required
                            />
                            {birthDateFieldError &&
                                <Label pointing prompt role={"alert"}>
                                    <FormattedMessage id="patients.form.birthdate.error"
                                                      description="Fill the date of birth"/>
                                </Label>}
                        </div>
                    </Form.Field>
                </Form.Group>
                <Form.TextArea
                    label={intl.formatMessage({id: "patients.form.about"})}
                    id='about'
                    placeholder={intl.formatMessage({id: "patients.form.about_placeholder"})}
                    value={about}
                    onChange={this.handleChange}/>
                <Button icon
                        labelPosition="left"
                        positive
                        onClick={this.handleSubmit}>
                    <Icon name={editing ? "save" : "add user"}/>
                    <FormattedMessage id={editing ? "patients.form.save" : "patients.form.create"}/>
                </Button>
                <Button icon
                        labelPosition="left"
                        onClick={() => dispatch({
                            activeView: editing
                                ? NavigationView.PATIENT_DETAIL
                                : NavigationView.PATIENTS_LIST
                        })}>
                    <Icon name="cancel"/>
                    <FormattedMessage id="patients.form.cancel"/>
                </Button>
            </Form>
        );
    }

}

export const PatientForm = injectIntl(withGlobalState(EditPatientFormImpl));