import React from "react";
import {Table} from "semantic-ui-react";

export function TableCellEllipsis({text}) {
    return (
        <Table.Cell>
            <div style={{position: "relative"}}>
                <p style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                    msTransform: "translateY(-50%)",
                    transform: "translateY(-50%)"
                }}>
                    {text}
                </p>
            </div>
        </Table.Cell>
    )
}