import {Divider, Header, Icon, List} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import {Centered} from "../../common/Centered";
import {calculateAge} from "../../common/Helpers";
import * as React from "react";
import {format} from "date-fns";

export const PatientPersonalData = ({patient, locale}) => {

    const patientAge = calculateAge(patient.birthDate);
    const patientAgeLabel = patientAge > 0 ? patientAge : "<1";

    return (
        <>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="address card outline"/>
                    <FormattedMessage id="patients.details.personal_data"
                                      description="Patient personal data"/>
                </Header>
            </Divider>
            <Centered>
                <List horizontal relaxed>
                    <List.Item>
                        <List.Header>
                            <FormattedMessage id="patients.details.first_name"/>
                        </List.Header>
                        {patient.name}
                    </List.Item>
                    <List.Item>
                        <List.Header>
                            <FormattedMessage id="patients.details.surname"/>
                        </List.Header>
                        {patient.surname}
                    </List.Item>
                    <List.Item>
                        <List.Header>
                            <FormattedMessage id="patients.details.age"/>
                        </List.Header>
                        {patientAgeLabel}
                    </List.Item>
                    <List.Item>
                        <List.Header>
                            <FormattedMessage id="patients.details.birthday"/>
                        </List.Header>
                        {format(new Date(patient.birthDate), "PPP", {locale: locale})}
                    </List.Item>
                </List>
            </Centered>
        </>
    );
}