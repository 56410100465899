import {Header, Icon} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const PatientListHeader = ({numFilteredPatients, numAllPatients}) => {
    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="group"/>
            <Header.Content>
                <FormattedMessage
                    id="patients.list.header"
                    description="Patient list"/>
                <Header.Subheader>
                    <FormattedMessage
                        id="patients.list.num_records"
                        description="Number of visible records"
                        values={{
                            filtered: numFilteredPatients,
                            patients: numAllPatients
                        }}/>
                </Header.Subheader>
            </Header.Content>
        </Header>
    );
}