import {Button} from "semantic-ui-react";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {DifferenceTable} from "./details/DifferenceTable";

export const Details = ({
                            initialActiveDifference = null,
                            differences,
                            availableComparisons,
                            dateFnsLocale,
                            onStartComparison,
                            onRemoveDifference,
                            onSelect
                        }) => {

    const intl = useIntl();
    const [activeDifference, setActiveDifference] = useState(initialActiveDifference);

    return (
        <>
            <DifferenceTable
                items={differences}
                dateFnsLocale={dateFnsLocale}
                onSelectDifference={onSelect}
            />
            <Button
                positive
                content={intl.formatMessage({id: "examinations.difference.compare_with"})}
                icon="clone outline"
                labelPosition="left"
                disabled={!availableComparisons}
                onClick={onStartComparison}
            />
            <Button
                negative
                content={intl.formatMessage({id: "examinations.difference.remove"})}
                icon="trash"
                labelPosition="left"
                floated="right"
                disabled={!activeDifference}
                onClick={() => {
                    setActiveDifference(null);
                    onRemoveDifference(activeDifference);
                }}
            />
        </>
    );
}
