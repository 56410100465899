import React from 'react';
import {Button, Dimmer, Dropdown, Header, Icon, Loader, Menu, Tab} from "semantic-ui-react";
import {View as SymmetryView} from "./symmetry/View";
import {ExaminationRemoveConfirmation} from "./ExaminationRemoveConfirmation";
import {ExaminationEdit} from "./ExaminationEdit";
import {Database} from "../app/AppDatabase";
import {downloadAsBinaryFile, updatePatientNumsBy} from "../common/Helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import {View as DifferenceView} from "./difference/View";
import {formatDistance} from "date-fns";
import {DateFnsLocale} from "../app/Parameters";
import {NavigationView, withGlobalState} from "../GlobalStateProvider";
import {View as ScanView} from "./scan/View";


class ExaminationDetailsImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            confirmationOpen: false,
            editOpen: false
        }
    }

    onKeyDown = (event) => {
        if (event.key === "ArrowLeft") {
            this.handlePrevExamination();
        } else if (event.key === "ArrowRight") {
            this.handleNextExamination();
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    removeExamination = (id) => {
        Database.deleteExamination(id).then(() => {
            const {dispatch, state} = this.props;
            try {
                dispatch({
                    activeView: NavigationView.EXAMINATIONS_LIST,
                    examinations: state.examinations.filter(examination => examination._id !== id),
                    activeExaminationId: null,
                    patients: updatePatientNumsBy(state.patients, state.activePatientId, -1, 0, 0)
                });
            } catch (err) {
                console.error(err);
            }
        });
    }

    setConfirmationOpen = (value) => {
        this.setState({
            confirmationOpen: value
        });
    }

    handleNextExamination = () => {
        const {state, dispatch} = this.props;
        const index = state.examinations.findIndex(elem => elem._id === state.activeExaminationId);
        if (index > 0) {
            dispatch({activeExaminationId: state.examinations[index - 1]._id});
        }
    }

    handlePrevExamination = () => {
        const {state, dispatch} = this.props;
        const index = state.examinations.findIndex(elem => elem._id === state.activeExaminationId);
        if (index < state.examinations.length - 1) {
            dispatch({activeExaminationId: state.examinations[index + 1]._id});
        }
    }

    prepareDiffLabel = (d1, d2) => {
        const {state} = this.props;
        return formatDistance(
            new Date(d1),
            new Date(d2),
            {
                locale: DateFnsLocale[state.settings.language],
                addSuffix: true
            })
    }

    preparePrevLabel = (examinations, examinationIndex) => {
        if (examinations.length === 0
            || examinationIndex === examinations.length - 1
            || examinationIndex + 1 > examinations.length) {
            return "";
        } else {
            return this.prepareDiffLabel(examinations[examinationIndex + 1].date, examinations[examinationIndex].date);
        }
    }

    prepareNextLabel = (examinations, examinationIndex) => {
        if (examinations.length === 0
            || examinationIndex === 0) {
            return "";
        } else {
            return this.prepareDiffLabel(examinations[examinationIndex - 1].date, examinations[examinationIndex].date);
        }
    }

    exportScan = () => {
        const {state} = this.props;
        const patient = state.patients.find(entry => entry._id === state.activePatientId);
        const examination = state.examinations.find(examination => examination._id === state.activeExaminationId);
        downloadAsBinaryFile(`${patient.surname}${patient.name}_${examination.name}_${examination.date}.ply`, examination.mesh);
    }

    render() {
        const {intl, dispatch, state} = this.props;
        const {confirmationOpen, editOpen} = this.state;
        const examination = state.examinations.find(examination => examination._id === state.activeExaminationId);
        const examinationDifferences = state.differences.filter(difference => difference.examination_id === examination._id);
        const symmetry = state.symmetries.find(symmetry => symmetry.examination_id === examination._id);
        const examinationIndex = state.examinations.findIndex(elem => elem._id === examination._id);
        const prevLabel = this.preparePrevLabel(state.examinations, examinationIndex);
        const nextLabel = this.prepareNextLabel(state.examinations, examinationIndex);
        const lastExamination = examinationIndex === 0;
        const firstExamination = examinationIndex === state.examinations.length - 1;

        const examinationPane = ({
            menuItem: (
                <Menu.Item key="main">
                    <Icon name="street view"/>
                    <FormattedMessage id="examinations.details.pane.main"/>
                </Menu.Item>
            ),
            render: () => <ScanView examination={examination}/>
        });
        const symmetryPane = ({
            menuItem: (
                <Menu.Item key="symmetry">
                    <Icon name="adjust" color={symmetry ? "green" : "red"}/>
                    <FormattedMessage id="examinations.details.pane.symmetry"/>
                </Menu.Item>),
            render: () => <SymmetryView examination={examination}
                                        symmetry={symmetry}/>
        });
        const panes = [{
            menuItem: (
                <Menu.Item key="difference">
                    <Icon name="clone outline"/>
                    <FormattedMessage id="examinations.details.pane.difference"
                                      values={{number: examinationDifferences.length}}/>
                </Menu.Item>),
            render: () =>
                <DifferenceView
                    differences={examinationDifferences}
                    examination={examination}
                />
        }];
        if (state.settings.symmetryIsPrimary) {
            panes.unshift(symmetryPane, examinationPane);
        } else {
            panes.unshift(examinationPane, symmetryPane);
        }
        return (
            <>
                <Button.Group
                    primary
                    floated="right">
                    <Button
                        content={intl.formatMessage({
                            id: "examinations.details.back"
                        })}
                        labelPosition="left"
                        icon="backward"
                        onClick={() => dispatch({
                            activeView: NavigationView.EXAMINATIONS_LIST,
                            activeExaminationId: null
                        })}
                    />
                    <Dropdown
                        className="button icon"
                        floating
                        trigger={<></>}
                        options={[
                            {
                                key: "edit",
                                icon: "edit",
                                text: intl.formatMessage({
                                    id: "examinations.details.edit"
                                }),
                                value: "edit",
                                onClick: () => this.setState({editOpen: true})
                            },
                            {
                                key: "export",
                                icon: "download",
                                text: intl.formatMessage({
                                    id: "examinations.details.export"
                                }),
                                value: "export",
                                onClick: this.exportScan
                            },
                            {
                                key: "remove",
                                icon: "trash",
                                text: intl.formatMessage({
                                    id: "examinations.details.remove"
                                }),
                                value: "remove",
                                onClick: () => this.setConfirmationOpen(true)
                            }
                        ]}
                    />
                </Button.Group>
                <Button.Group floated="right" style={{"marginRight": "15px"}}>
                    <Button
                        content={intl.formatMessage({
                            id: firstExamination
                                ? "examinations.details.previous.disabled"
                                : "examinations.details.previous",
                        }, {time: prevLabel})}
                        labelPosition="left"
                        icon="left chevron"
                        disabled={firstExamination}
                        onClick={this.handlePrevExamination}
                    />
                    <Button
                        content={intl.formatMessage({
                            id: lastExamination
                                ? "examinations.details.next.disabled"
                                : "examinations.details.next",
                        }, {time: nextLabel})}
                        labelPosition='right'
                        disabled={lastExamination}
                        icon='right chevron'
                        onClick={this.handleNextExamination}
                    />
                </Button.Group>
                <Header as="h2" style={{marginTop: "0px"}}>
                    <Icon name="heartbeat"/>
                    <Header.Content>
                        <FormattedMessage id="examinations.details.header"/>
                        <Header.Subheader>{examination.name}</Header.Subheader>
                    </Header.Content>
                </Header>

                <Tab menu={{secondary: true, pointing: true}}
                     onTabChange={(_, {activeIndex}) => this.setState({activeIndex})}
                     panes={panes}/>
                <Dimmer inverted active={state.computing}>
                    <Loader size="massive"/>
                    <FormattedMessage id="examinations.details.computing"/>
                </Dimmer>
                <ExaminationRemoveConfirmation open={confirmationOpen}
                                               examination={examination}
                                               onCancel={() => this.setConfirmationOpen(false)}
                                               onRemove={this.removeExamination}/>
                <ExaminationEdit open={editOpen}
                                 examination={examination}
                                 onCancel={() => this.setState({editOpen: false})}
                                 onSubmit={() => this.setState({editOpen: false})}/>
            </>
        );
    }

}

export const ExaminationDetails = injectIntl(withGlobalState(ExaminationDetailsImpl));