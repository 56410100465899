import React from 'react';

export const NavigationView = {
    PATIENTS_LIST: "patients_list",
    PATIENT_DETAIL: "patient_detail",
    PATIENT_ADD: "patient_add",
    PATIENT_EDIT: "patient_edit",
    EXAMINATION_DETAIL: "examination_detail",
    EXAMINATIONS_LIST: "examinations_list",
    SCANNING_VIEW: "scanning",
    SCANNING_AUTO_START: "scanning_perform",
    SETTINGS: "settings",
    SCANNER_TESTING: "scanner_testing"
}


const defaultGlobalState = {
    patients: [],
    examinations: [],
    differences: [],
    symmetries: [],
    activeView: NavigationView.PATIENTS_LIST,
    activePatient: null,
    // We work on an active patient, but we can preview another patient at the same time
    activePatientId: null,
    previewPatientId: null,
    activeExaminationId: null,
    settings: null,
    computing: false,
    showScannerTestingView: false
};
const globalStateContext = React.createContext(defaultGlobalState);
const dispatchStateContext = React.createContext(undefined);

export const GlobalStateProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(
        (state, newValue) => ({...state, ...newValue}),
        defaultGlobalState
    );
    return (
        <globalStateContext.Provider value={state}>
            <dispatchStateContext.Provider value={dispatch}>
                {children}
            </dispatchStateContext.Provider>
        </globalStateContext.Provider>
    );
};

export const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
];

export const withGlobalState = (Component) => {
    return function WrappedComponent(props) {
        const [state, dispatch] = useGlobalState();
        return <Component {...props} state={state} dispatch={dispatch}/>;
    }
}