import {Container, Divider, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import {format} from "date-fns";

export const Details = ({examination}) => (
    <>
        <p>
            <strong><FormattedMessage id="examinations.overview.name" description="Examination name"/></strong>
            {examination.name}
        </p>
        <p>
            <strong><FormattedMessage id="examinations.overview.date" description="Examination date"/></strong>
            {format(new Date(examination.date), "dd/MM/yyyy")}
        </p>
        <p>
            <strong><FormattedMessage id="examinations.overview.time" description="Examination time"/></strong>
            {format(new Date(examination.date), "HH:mm:ss")}
        </p>

        <Divider horizontal>
            <Header as="h4">
                <Icon name="stethoscope"/>
                <FormattedMessage id="examinations.overview.note" description="Other information"/>
            </Header>
        </Divider>
        <Segment basic>
            <Container>
                <Segment>
                    {examination.note === ""
                        ? <FormattedMessage id="examinations.overview.no_note" description="No information"/>
                        : examination.note.split('\n').map((line, key) => <p key={key}>{line}</p>)}
                </Segment>
            </Container>
        </Segment>
    </>);
// :
