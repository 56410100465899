import {Header, Icon} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import * as React from "react";

export const PatientEditHeader = () => {

    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="edit"/>
            <Header.Content>
                <FormattedMessage id={"patients.details.patient"}/>
                <Header.Subheader>
                    <FormattedMessage id={"patients.edit.edit_patient_data"}/>
                </Header.Subheader>
            </Header.Content>
        </Header>
    );
}