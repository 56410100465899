import * as React from "react";
import {Progress} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";

export const ConnectionStatus = {
    Idle: 'Idle',
    Offline: 'Offline',
    Online: 'Online'
}

export class ConnectionTestResult extends React.Component {

    selectConnectionStatusMessage = (status) => {
        switch (status) {
            case ConnectionStatus.Offline:
                return <FormattedMessage id='settings.connection.offline'
                                         description='Connection is offline'/>;
            case ConnectionStatus.Online:
                return <FormattedMessage id='settings.connection.online'
                                         description='Connection is online'/>;
            default:
                return <FormattedMessage id='settings.connection.test_connection'
                                         description='Perform connection test'/>;
        }
    }

    render() {
        const status = this.props.connectionStatus;
        return (
            <Progress percent={100}
                      disabled={status === ConnectionStatus.Idle}
                      error={status === ConnectionStatus.Offline}
                      success={status === ConnectionStatus.Online}>
                {this.selectConnectionStatusMessage(status)}
            </Progress>
        );
    }

}