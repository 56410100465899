import {Container, Divider, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import * as React from "react";

export const PatientNote = ({patient}) => {

    return (
        <>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="pencil"/>
                    <FormattedMessage id="patients.details.note"
                                      description="Note about the patient"/>
                </Header>
            </Divider>
            <Container>
                <Segment>
                    {patient.about.split('\n').map((line, key) => <p key={key}>{line}</p>)}
                </Segment>
            </Container>
        </>
    );
}