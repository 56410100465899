import React, {useState} from 'react';
import {ExaminationImport} from "./list/ExaminationImport";
import {NavigationView, useGlobalState} from "../GlobalStateProvider";
import {ExaminationMissing} from "./list/ExaminationMissing";
import {ExaminationTable} from "./list/ExaminationTable";
import {ExaminationListHeader} from "./list/ExaminationListHeader";
import {ExaminationListActions} from "./list/ExaminationListActions";

export const ExaminationListView = () => {

    const [searchQuery, setSearchQuery] = useState("")
    const [importOpen, setImportOpen] = useState(false);
    const [state, dispatch] = useGlobalState();

    const displayExamination = (examinationId) => {
        dispatch({
            activeView: NavigationView.EXAMINATION_DETAIL,
            activeExaminationId: examinationId
        });
    }

    if (!state.examinations.length) {
        return (
            <>
                <ExaminationMissing
                    onAddNewExamination={() => dispatch({activeView: NavigationView.SCANNING_AUTO_START})}
                    onImportExamination={() => setImportOpen(true)}
                />
                <ExaminationImport open={importOpen}
                                   patientId={state.activePatientId}
                                   onCancel={() => setImportOpen(false)}/>
            </>
        );
    }

    const lowerSearchQuery = searchQuery.toLowerCase();
    const filteredExaminations = lowerSearchQuery !== ""
        ? state.examinations.filter(({name, note}) =>
            name.toLowerCase().includes(lowerSearchQuery) || note.toLowerCase().includes(lowerSearchQuery))
        : state.examinations;

    return (
        <>
            <ExaminationListActions
                onAddNewExamination={() => dispatch({activeView: NavigationView.SCANNING_AUTO_START})}
                onImportExamination={() => setImportOpen(true)}
            />
            <ExaminationListHeader
                numFilteredExaminations={filteredExaminations.length}
                numAllExaminations={state.examinations.length}
            />
            <ExaminationTable
                onSearchQueryChange={setSearchQuery}
                onExaminationClick={displayExamination}
                symmetries={state.symmetries}
                differences={state.differences}
                items={filteredExaminations}
            />
            <ExaminationImport open={importOpen}
                               patientId={state.activePatientId}
                               onCancel={() => setImportOpen(false)}/>
        </>
    );
}
