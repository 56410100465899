import React from "react";
import {Button, Form, Icon, Label, Modal} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {selectLocaleForDatepicker} from "../app/Parameters";
import {TimeInput} from "../common/TimeInput";
import {byDateSorter, combineDateAndTime} from "../common/Helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import {withGlobalState} from "../GlobalStateProvider";
import {Database} from "../app/AppDatabase";

export class ExaminationEdit extends React.Component {

    render() {
        if (!this.props.open) return null;
        return <ExaminationEditWrapper {...this.props}/>
    }
}

export class ExaminationEditImpl extends React.Component {

    constructor(props) {
        super(props);

        const datetime = new Date(props.examination.date);
        this.state = {
            name: props.examination.name,
            note: props.examination.note,
            date: datetime,
            time: datetime,
            invalidForm: false
        };
    }

    isValidForm = () => {
        const {date, time} = this.state;
        return date && time;
    }

    handleSubmit = async () => {
        const {note, name, date, time} = this.state;
        const {examination, onSubmit, state, dispatch} = this.props;
        if (this.isValidForm()) {
            this.setState({invalidForm: false});
            const savedExamination = await Database.updateExamination(
                {...examination, note, name, date: combineDateAndTime(date, time)}
            );
            const updatedExaminations = state.examinations.map(e => e._id === savedExamination._id ? savedExamination : e)
            dispatch({
                examinations: updatedExaminations.sort(byDateSorter)
            });
            onSubmit();
        } else {
            this.setState({invalidForm: true});
        }
    }

    handleChange = (_, {id, value}) => {
        this.setState({[id]: value});
    }

    render() {
        const {open, onCancel, intl, state} = this.props;
        const {name, note, date, time, invalidForm} = this.state;
        const dateFieldError = invalidForm && !date;
        const timeFieldError = invalidForm && !time
            ? {
                content: intl.formatMessage({
                    id: "examinations.form.time.error",
                    description: "Fill the missing time"
                }),
                pointing: "above"
            }
            : false;
        return (
            <Modal open={open} size="small">
                <Modal.Header>
                    <Icon name="edit"/>
                    <FormattedMessage id="examinations.form.header"
                                      description="Header of the examination edit modal view"/>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Input id="name"
                                        label={intl.formatMessage({
                                            id: "examinations.form.name",
                                            description: "Examination name"
                                        })}
                                        value={name}
                                        onChange={this.handleChange}/>
                            <Form.Field error={dateFieldError}>
                                <div className={"fullWidthSemanticDatepicker"}>
                                    <SemanticDatepicker
                                        label={intl.formatMessage({
                                            id: "examinations.form.date",
                                            description: "Examination date"
                                        })}
                                        id="initialDate"
                                        value={date ? new Date(date) : undefined}
                                        locale={selectLocaleForDatepicker(state.settings)}
                                        showToday
                                        onChange={(e, {value}) => this.setState({date: value})}
                                        required
                                    />
                                    {dateFieldError &&
                                        <Label pointing prompt role={"alert"}>
                                            <FormattedMessage id="examinations.form.date.error"
                                                              description="Fill missing date"/>
                                        </Label>}
                                </div>
                            </Form.Field>
                            <TimeInput value={time}
                                       label={intl.formatMessage({
                                           id: "examinations.form.time",
                                           description: "Examination time"
                                       })}
                                       required
                                       error={timeFieldError}
                                       onChange={(value) => this.setState({time: value})}/>
                            <Form.TextArea id="note"
                                           label={intl.formatMessage({
                                               id: "examinations.form.note",
                                               description: "Other information"
                                           })}
                                           value={note}
                                           onChange={this.handleChange}
                                           style={{minHeight: "250px"}}/>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={intl.formatMessage({
                            id: "examinations.form.cancel",
                            description: "Cancel edit"
                        })}
                        labelPosition="left"
                        icon="close" onClick={onCancel}
                    />
                    <Button
                        positive
                        content={intl.formatMessage({
                            id: "examinations.form.save",
                            description: "Save edit changes"
                        })}
                        labelPosition="left"
                        icon="save"
                        onClick={this.handleSubmit}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

}

export const ExaminationEditWrapper = injectIntl(withGlobalState(ExaminationEditImpl));