import {Icon, Label, Table} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import {format, formatDistance} from "date-fns";
import {DateFnsLocale} from "../../../app/Parameters";
import {TableCellEllipsis} from "../../../common/TableCellEllipsis";
import React, {useState} from "react";
import {useGlobalState} from "../../../GlobalStateProvider";
import {withPagination} from "../../../common/withPagination";

const DifferenceComparisonTableImpl = ({
                                           examination,
                                           items: paginatedExaminations,
                                           onSelectDifference,
                                           selectedDifferenceId
                                       }) => {

    const [state] = useGlobalState();
    const [selectedExamination, setSelectedExamination] = useState(null);

    // const filteredExaminations = examinations;
    // const lowerSearchQuery = searchQuery.toLowerCase();
    // const filteredExaminations = lowerSearchQuery !== ""
    //     ? examinations.filter(({name}) => name.toLowerCase().includes(lowerSearchQuery))
    //     : examinations;

    let currentDate = '';
    let ribbon = null;

    return (
        <>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{width: "115px"}}>
                        <Icon name="calendar outline"/>
                        <FormattedMessage id="examinations.comparison.date"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id="examinations.comparison.name"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="clock outline"/>
                        <FormattedMessage id="examinations.comparison.time"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}> </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="hourglass half"/>
                        <FormattedMessage id="examinations.comparison.time_difference"/>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {paginatedExaminations.map(({_id, name, date}) => {
                    const formattedDate = format(new Date(date), "dd/MM/yyyy");
                    const formattedTime = format(new Date(date), "HH:mm");
                    if (currentDate !== formattedDate) {
                        currentDate = formattedDate
                        ribbon = <Label ribbon>{formattedDate}</Label>
                    } else {
                        ribbon = null
                    }
                    const inTheFuture = (new Date(date) - new Date(examination.date)) > 0;
                    const timeDistance = formatDistance(
                        new Date(date),
                        new Date(examination.date),
                        {
                            locale: DateFnsLocale[state.settings.language],
                            addSuffix: true
                        });
                    return (
                        <Table.Row key={_id}
                                   active={selectedExamination === _id}
                                   onClick={() => {
                                       setSelectedExamination(_id)
                                       onSelectDifference(_id)
                                   }}>
                            <Table.Cell>{ribbon}</Table.Cell>
                            <TableCellEllipsis text={name}/>
                            <Table.Cell style={{textAlign: "center"}}>{formattedTime}</Table.Cell>
                            <Table.Cell>
                                {inTheFuture
                                    ? <Icon name="angle double up" color="green"/>
                                    : <Icon name="angle double down" color="red"/>}
                            </Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{timeDistance}</Table.Cell>
                        </Table.Row>);
                })}
            </Table.Body>
        </>
    );
}

export const DifferenceComparisonTable = withPagination(DifferenceComparisonTableImpl, 10);
