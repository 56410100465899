import React, {useEffect, useState} from "react";
import {DifferenceComparisonView} from "./DifferenceComparisonView";
import {Database} from "../../app/AppDatabase";
import {byDateSorter, updatePatientNumsBy} from "../../common/Helpers";
import {Details} from "./Details";
import {DateFnsLocale} from "../../app/Parameters";
import {DetailsLayout} from "../DetailsLayout";
import {ScanViewer} from "../../common/ScanViewer";
import {useGlobalState} from "../../GlobalStateProvider";

export const View = ({examination, differences}) => {

    const [state, dispatch] = useGlobalState();
    const [activeDifferenceId, setActiveDifferenceId] = useState(differences.length > 0 ? differences[0]._id : null);
    const [selectionOpen, setSelectionOpen] = useState(false);

    const removeDifference = (id) => {
        Database.deleteDifference(id).then(() => {
            dispatch({
                patients: updatePatientNumsBy(state.patients, state.activePatientId, 0, 0, -1),
                differences: state.differences.filter(e => e._id !== id)
            });
        });
    }

    const lookupExamination = Object.assign({}, ...state.examinations.map((x) => ({[x._id]: x})));

    const extendedDifferences = differences.map(entry => {
        const baseExamination = lookupExamination[entry.examination_id]
        const otherExamination = lookupExamination[entry.other_examination_id];
        entry.name = otherExamination ? otherExamination.name : undefined;
        entry.date = otherExamination ? otherExamination.date : new Date(0, 0); // year 1899
        entry.base_date = baseExamination.date;
        entry.valid = otherExamination !== undefined;
        return entry;
    });
    const difference = extendedDifferences.find(e => e._id === activeDifferenceId);

    // Filter only examinations which were not used for difference
    const usedExaminations = differences.map(entry => entry.other_examination_id);
    const selectableExaminations = state.examinations
        .filter(entry => entry._id !== examination._id)
        .filter(entry => !usedExaminations.includes(entry._id));

    // TODO: no search here for the moment
    const lowerSearchQuery = "";
    const filteredDifferences = (lowerSearchQuery !== ""
        ? extendedDifferences.filter(({name}) => name.toLowerCase().includes(lowerSearchQuery))
        : extendedDifferences)
        .sort(byDateSorter);

    useEffect(() => {
        async function loadMissingDistanceMap() {
            // Read the scan mesh if not available
            if (!examination.mesh) {
                const fullExamination = await Database.readExamination(examination._id);
                const updatedExaminations = state.examinations.map(entry => entry._id === examination._id ? fullExamination : entry);
                dispatch({examinations: updatedExaminations});
            }
            if (difference && !difference.distanceMap) {
                const fullDifference = await Database.readDifference(difference._id);
                const updatedDifferences = state.differences.map(entry => entry._id === difference._id ? fullDifference : entry);
                dispatch({differences: updatedDifferences});
            }
        }

        loadMissingDistanceMap().then();
    });

    return (
        <>
            <DetailsLayout
                details={<Details
                    initialActiveDifference={activeDifferenceId}
                    differences={filteredDifferences}
                    availableComparisons={selectableExaminations.length > 0}
                    dateFnsLocale={DateFnsLocale[state.settings.language]}
                    onSelect={(id) => setActiveDifferenceId(id)}
                    onStartComparison={() => setSelectionOpen(true)}
                    onRemoveDifference={removeDifference}
                />}
                scanViewer={<ScanViewer dimmed={differences.length === 0}
                                        loading={false}
                                        dimmedIcon="calculator"
                                        mesh={examination.mesh}
                                        distanceMap={difference ? difference.distanceMap : undefined}
                />}
            />
            <DifferenceComparisonView
                open={selectionOpen}
                examination={examination}
                examinations={selectableExaminations}
                onClose={() => setSelectionOpen(false)}
                onComputed={(id) => setSelectionOpen(false)}
            />
        </>
    );

}
