import React, {useState} from "react";
import {PatientTable} from "./list/PatientTable";
import {NavigationView, useGlobalState} from "../GlobalStateProvider";
import {PatientMissing} from "./list/PatientMissing";
import {PatientListHeader} from "./list/PatientListHeader";
import {PatientListActions} from "./list/PatientListActions";

export const PatientListView = () => {

    const [searchQuery, setSearchQuery] = useState("")
    const [state, dispatch] = useGlobalState();

    const addNewPatient = () => {
        dispatch({
            activeView: NavigationView.PATIENT_ADD,
        });
    }

    if (!state.patients.length) {
        return <PatientMissing onAddNewPatient={addNewPatient}/>
    }

    const displayPatient = (patientId) => {
        dispatch({
            activeView: NavigationView.PATIENT_DETAIL,
            previewPatientId: patientId
        });
    }

    const lowerSearchQuery = searchQuery.toLowerCase();
    const filteredPatients = searchQuery !== ""
        ? state.patients.filter(({name, surname, about}) =>
            name.toLowerCase().includes(lowerSearchQuery)
            || surname.toLowerCase().includes(lowerSearchQuery)
            || about.toLowerCase().includes(lowerSearchQuery))
        : state.patients;

    return (
        <>
            <PatientListActions
                onAddNewPatient={addNewPatient}
            />
            <PatientListHeader
                numFilteredPatients={filteredPatients.length}
                numAllPatients={state.patients.length}
            />
            <PatientTable
                onSearchQueryChange={setSearchQuery}
                onPatientClick={displayPatient}
                activePatientId={state.activePatientId}
                items={filteredPatients}
            />
        </>
    );

}