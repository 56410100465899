import React from "react";
import {Button, Dimmer, Form, Icon, Label, Loader, Modal} from "semantic-ui-react";
import {Database} from "../../app/AppDatabase";
import {TimeInput} from "../../common/TimeInput";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {selectLocaleForDatepicker} from "../../app/Parameters";
import {byDateSorter, combineDateAndTime} from "../../common/Helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import {NavigationView, withGlobalState} from "../../GlobalStateProvider";


class ExaminationImportImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            note: "",
            date: new Date(),
            time: new Date(),
            mesh: null,
            filename: null,
            loading: false,
            invalidForm: false
        };
    }

    handleChange = (_, {id, value}) => {
        this.setState({[id]: value});
    }

    handleCancel = () => {
        this.setState({
            name: "",
            note: "",
            mesh: null,
            date: new Date(),
            time: new Date(),
            filename: null,
            invalidForm: false
        });
        this.props.onCancel();
    }

    handleImport = () => {
        const {name, note, mesh, date, time} = this.state;
        if (this.isValidForm()) {
            this.setState({loading: true, invalidForm: false});
            const {patientId} = this.props;
            const newExamination = {
                patient_id: patientId,
                name: name,
                note: note,
                date: combineDateAndTime(date, time),
                mesh: mesh
            };
            Database.createExamination(newExamination).then(examination => {
                this.setState({loading: false});
                const {dispatch, state} = this.props;
                const updatedExaminations = state.examinations;
                updatedExaminations.push(examination);
                updatedExaminations.sort(byDateSorter);
                dispatch({
                    activeView: NavigationView.EXAMINATION_DETAIL,
                    activeExaminationId: examination._id,
                    examinations: updatedExaminations
                });
            });
        } else {
            this.setState({invalidForm: true})
        }
    }

    upload = (event) => {
        const {intl} = this.props;
        const {name, note} = this.state;
        const filename = event.target.files[0].name;

        event.target.files[0].arrayBuffer().then(mesh => {
            this.setState({
                mesh: mesh,
                filename: filename,
                name: name.length === 0
                    ? intl.formatMessage({
                            id: "examinations.import.default_name",
                            description: "Default name of an imported examination"
                        },
                        {"filename": filename}
                    )
                    : name,
                note: note.length === 0
                    ? intl.formatMessage({
                            id: "examinations.import.default_note",
                            description: "Default note of an imported examination"
                        },
                        {"filename": filename}
                    )
                    : note
            })
        });
    }

    isValidName = (name) => name.length > 0

    isValidMesh = (mesh) => mesh && mesh.byteLength > 0;

    isValidDate = (date) => date;

    isValidTime = (time) => time;

    isValidForm = () => {
        const {name, mesh, date, time} = this.state;
        return this.isValidName(name)
            && this.isValidMesh(mesh)
            && this.isValidDate(date)
            && this.isValidTime(time);
    }

    render() {
        const {open, state, intl} = this.props;
        const {name, note, date, time, mesh, filename, loading, invalidForm} = this.state;

        const hasMeshFieldError = invalidForm && !this.isValidMesh(mesh);
        const nameFieldError = invalidForm && !this.isValidName(name)
            ? {
                content: intl.formatMessage({
                    id: "examinations.import.name.error",
                    description: "Fill examination name"
                }), pointing: "above"
            }
            : false;
        const dateFieldError = invalidForm && !this.isValidDate(date);
        const timeFieldError = invalidForm && !this.isValidTime(time)
            ? {
                content: intl.formatMessage({
                    id: "examinations.import.time.error",
                    description: "Fill examination time"
                }), pointing: "above"
            }
            : false;

        return (
            <Modal open={open} size="small">
                <Modal.Header>
                    <Icon name="upload"/>
                    <FormattedMessage
                        id="examinations.import.header"
                        description="Header of the examination import window"
                    />
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Field required
                                        error={hasMeshFieldError}>
                                <label>
                                    <FormattedMessage
                                        id="examinations.import.file"
                                        description="Scan file"/>
                                </label>
                                <Button
                                    as="label"
                                    positive={mesh !== null}
                                    icon
                                    htmlFor="importScanMesh"
                                    labelPosition="left">
                                    <Icon name="upload"/>
                                    {filename === null
                                        ? <FormattedMessage
                                            id="examinations.import.file.load"
                                            description="Load scan"/>
                                        : filename}
                                </Button>
                                <input hidden type="file" id="importScanMesh" onChange={this.upload}/>
                                {hasMeshFieldError &&
                                <Label pointing prompt role={"alert"}>
                                    <FormattedMessage
                                        id="examinations.import.file.error"
                                        description="Please load the scan file"/>
                                </Label>}
                            </Form.Field>
                            <Form.Input id="name"
                                        label={intl.formatMessage({
                                            id: "examinations.import.name",
                                            description: "Examination name"
                                        })}
                                        required
                                        value={name}
                                        error={nameFieldError}
                                        onChange={this.handleChange}/>
                            <Form.Field error={dateFieldError}>
                                <div className={"fullWidthSemanticDatepicker"}>
                                    <SemanticDatepicker
                                        label={intl.formatMessage({
                                            id: "examinations.import.date",
                                            description: "Examination date"
                                        })}
                                        id="initialDate"
                                        value={date ? new Date(date) : undefined}
                                        locale={selectLocaleForDatepicker(state.settings)}
                                        showToday
                                        onChange={(e, {value}) => this.setState({date: value})}
                                        required
                                    />
                                    {dateFieldError &&
                                    <Label pointing prompt role={"alert"}>
                                        <FormattedMessage
                                            id="examinations.import.date.error"
                                            description="Please fill the examination date"/>
                                    </Label>}
                                </div>
                            </Form.Field>
                            <TimeInput value={time}
                                       label={intl.formatMessage({
                                           id: "examinations.import.time",
                                           description: "Examination time"
                                       })}
                                       required
                                       error={timeFieldError}
                                       onChange={(newTime) => this.setState({time: newTime})}/>
                            <Form.TextArea id="note"
                                           label={intl.formatMessage({
                                               id: "examinations.import.note",
                                               description: "Examination note"
                                           })}
                                           value={note}
                                           onChange={this.handleChange}
                                           style={{minHeight: "250px"}}/>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={intl.formatMessage({id: "examinations.import.cancel"})}
                        labelPosition="left"
                        icon="close"
                        onClick={this.handleCancel}
                    />
                    <Button
                        positive
                        content={intl.formatMessage({id: "examinations.import.save"})}
                        labelPosition="left"
                        icon="save"
                        onClick={this.handleImport}
                    />
                </Modal.Actions>
                <Dimmer inverted active={loading}>
                    <Loader size="massive">
                        <FormattedMessage id="examinations.import.loading"/>
                    </Loader>
                </Dimmer>
            </Modal>
        );
    }

}

export const ExaminationImport = injectIntl(withGlobalState(ExaminationImportImpl));