import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find'
import {DefaultSettings} from "./Parameters";

export class AppDatabase {

    constructor() {
        PouchDB.plugin(PouchDBFind);
        this.patients = new PouchDB("patients", {revs_limit: 1, auto_compaction: true});
        this.examinations = new PouchDB("examinations", {revs_limit: 1, auto_compaction: true});
        this.symmetries = new PouchDB("symmetries", {revs_limit: 1, auto_compaction: true});
        this.differences = new PouchDB("differences", {revs_limit: 1, auto_compaction: true});
        this.configuration = new PouchDB("configuration", {revs_limit: 1, auto_compaction: true});

        // Create index for the faster lookup
        this.setupIndices().then(() => {
            console.log("AppDatabase", "Query indices have been set up.");
        });
    }

    setupIndices = async () => {
        try {
            await this.examinations.createIndex({index: {fields: ["patient_id"]}});
            await this.symmetries.createIndex({index: {fields: ["patient_id", "examination_id"]}});
            await this.differences.createIndex({index: {fields: ["patient_id", "examination_id"]}});
        } catch (err) {
            console.log(err);
        }
    }

    /*
     * Patients CRUD
     */
    createPatient = async (newPatient) => {
        const writeStatus = await this.patients.post(newPatient);
        return await this.readPatient(writeStatus.id);
    }

    readPatient = async (patientId) => {
        try {
            return await this.patients.get(patientId, {attachments: false});
        } catch (err) {
            console.error(err);
        }
    }

    countByPatients = async (database) => {
        const queryResult = await database.find({
            selector: {},
            fields: ["patient_id"]
        });
        const onlyPatientIds = queryResult.docs.map(({patient_id}) => patient_id);
        return onlyPatientIds.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
    }

    updatePatientsCounts = async (patients) => {
        const examinationsNum = await this.countByPatients(this.examinations);
        const symmetriesNum = await this.countByPatients(this.symmetries);
        const differencesNum = await this.countByPatients(this.differences);

        return patients.map(patient => {
            patient.num_examinations = examinationsNum.get(patient._id) || 0;
            patient.num_symmetries = symmetriesNum.get(patient._id) || 0;
            patient.num_differences = differencesNum.get(patient._id) || 0;
            return patient;
        });
    }

    updatePatientCounts = async (patientId, patients) => {
        const perPatientQuery = {
            selector: {patient_id: patientId},
            fields: []
        };
        const patientExaminationsNum = (await this.examinations.find(perPatientQuery)).docs.length;
        const patientSymmetriesNum = (await this.symmetries.find(perPatientQuery)).docs.length;
        const patientDifferencesNum = (await this.differences.find(perPatientQuery)).docs.length;

        return patients.map(patient => {
            if (patient._id === patientId) {
                return {
                    ...patient,
                    num_examinations: patientExaminationsNum,
                    num_symmetries: patientSymmetriesNum,
                    num_differences: patientDifferencesNum
                };
            } else {
                return patient;
            }
        })
    }

    listPatients = async (countScans = false) => {
        try {
            const patients = await this.patients.allDocs({include_docs: true, attachments: false});
            let extractedPatients = patients.rows.map(({doc}) => doc);
            if (countScans) {
                extractedPatients = this.updatePatientsCounts(extractedPatients);
            }
            return extractedPatients;
        } catch (err) {
            console.error(err);
        }
    }

    updatePatient = async (updatedPatient) => {
        try {
            const patient = await this.patients.get(updatedPatient._id);
            return await this.patients.put({
                ...patient,
                ...updatedPatient
            });
        } catch (err) {
            console.error(err);
        }
    }


    deletePatient = async (id) => {
        try {
            const patient = await this.patients.get(id, {attachments: false});
            // Find examination(s) connected to the patient
            const examinations = await this.examinations.find({
                selector: {patient_id: patient._id},
                fields: ["_id"]
            });
            // Remove each examination
            for (const {_id} of examinations.docs) {
                await this.deleteExamination(_id);
            }
            return await this.patients.remove(patient);
        } catch (err) {
            console.error(err);
        }
    }

    onPatientsChange = (callback) => {
        return this.patients.changes({
            since: 'now',
            live: true,
            include_docs: false
        }).on('change', function () {
            callback()
        }).on('complete', function (info) {
            console.log(info)
        }).on('error', function (err) {
            console.error(err);
        });
    };

    /*
     * Examinations CRUD
     */
    createExamination = async ({_id, patient_id, name, note, date, mesh}) => {
        const attachmentType = 'application/octet-stream';
        try {
            // Check if patient exists
            const patient = await this.patients.get(patient_id, {attachments: false});
            const newExamination = {
                patient_id: patient._id,
                name: name,
                note: note,
                date: date,
                _attachments: {
                    "scan.ply": {
                        content_type: attachmentType,
                        data: new Blob([mesh], {type: attachmentType})
                    }
                }
            };
            if (_id) {
                newExamination._id = _id;
            }
            const writeStatus = await this.examinations.post(newExamination);
            return await this.readExamination(writeStatus.id);
        } catch (err) {
            console.error(err);
        }
    }

    readExamination = async (examinationId) => {
        try {
            const examination = await this.examinations.get(examinationId, {attachments: true, binary: true});
            const mesh = await examination._attachments["scan.ply"].data.arrayBuffer();
            delete examination._attachments;
            examination["mesh"] = mesh;
            return examination;
        } catch (err) {
            console.error(err);
        }
    }

    updateExamination = async (updatedExamination) => {
        try {
            // We need to make sure to work on the latest revision
            delete updatedExamination["_rev"];
            const currentExamination = await this.examinations.get(updatedExamination._id);
            const newExamination = {...currentExamination, ...updatedExamination};
            await this.examinations.put(newExamination);
            return newExamination;
        } catch (err) {
            console.error(err);
        }
    }

    deleteExamination = async (id) => {
        try {
            const examination = await this.examinations.get(id, {attachments: false});
            // Find symmetries connected to the examination/scan
            const symmetries = await this.symmetries.find({
                selector: {examination_id: examination._id},
                fields: ["_id"]
            });
            // Remove each symmetry first (actually, it should be only one symmetry)
            for (const {_id} of symmetries.docs) {
                await this.deleteSymmetry(_id);
            }
            const differences = await this.differences.find({
                selector: {examination_id: examination._id},
                fields: ["_id"]
            })
            // Remove all differences assigned with this examination
            for (const {_id} of differences.docs) {
                await this.deleteDifference(_id);
            }
            return await this.examinations.remove(examination);
        } catch (err) {
            console.error(err);
        }
    }

    listExaminations = async (patientId) => {
        try {
            const queryResult = await this.examinations.find({
                selector: {patient_id: patientId},
                fields: ["_id", "_rev", "date", "name", "note", "patient_id"] // Avoid attachments!
            });
            return queryResult.docs;
        } catch (err) {
            console.error(err);
        }
    }

    listAllExaminations = async (withAttachments = true) => {
        try {
            const docs = await this.examinations.allDocs({include_docs: true, attachments: withAttachments})
            return docs.rows
                .map(({doc}) => doc) // Retrieve proper documents
                .filter(doc => !doc._id.startsWith("_design"));  // Omitting query indices
        } catch (err) {
            console.error(err);
        }
    }

    /*
     * Symmetries
     */
    createSymmetry = async ({_id, patient_id, examination_id, note, distanceMap}) => {
        const attachmentType = 'application/octet-stream';
        try {
            // Check if patient exists
            const patient = await this.patients.get(patient_id, {attachments: false});
            // Check if examination exists
            const examination = await this.examinations.get(examination_id, {attachments: false});
            const newSymmetry = {
                patient_id: patient._id,
                examination_id: examination._id,
                note: note,
                _attachments: {
                    "symmetry.pdm": {
                        content_type: attachmentType,
                        data: new Blob([distanceMap], {type: attachmentType})
                    }
                }
            };
            if (_id) {
                newSymmetry._id = _id;
            }
            const writeStatus = await this.symmetries.post(newSymmetry);
            return await this.readSymmetry(writeStatus.id);
        } catch (err) {
            console.error(err);
        }
    }

    readSymmetry = async (symmetryId) => {
        try {
            const symmetry = await this.symmetries.get(symmetryId, {attachments: true, binary: true});
            const distanceMap = await symmetry._attachments["symmetry.pdm"].data.arrayBuffer();
            delete symmetry._attachments;
            symmetry["distanceMap"] = distanceMap;
            return symmetry;
        } catch (err) {
            console.error(err);
        }
    }

    listSymmetries = async (patientId) => {
        try {
            const queryResult = await this.symmetries.find({
                selector: {patient_id: patientId},
                // Avoid attachments!
                fields: ["_id", "_rev", "note", "patient_id", "examination_id"],
            });
            return queryResult.docs;
        } catch (err) {
            console.error(err);
        }
    }

    listAllSymmetries = async (withAttachments = true) => {
        try {
            const docs = await this.symmetries.allDocs({include_docs: true, attachments: withAttachments})
            return docs.rows
                .map(({doc}) => doc) // Retrieve proper documents
                .filter(doc => !doc._id.startsWith("_design"));  // Omitting query indices
        } catch (err) {
            console.error(err);
        }
    }

    deleteSymmetry = async (symmetryId) => {
        try {
            const symmetry = await this.symmetries.get(symmetryId, {attachments: false});
            return await this.symmetries.remove(symmetry);
        } catch (err) {
            console.error(err)
        }
    }

    /*
     * Differences
     */
    createDifference = async ({_id, patient_id, examination_id, other_examination_id, distanceMap, note}) => {
        const attachmentType = 'application/octet-stream';
        try {
            // Check if patient exists
            const patient = await this.patients.get(patient_id, {attachments: false});
            // Check if the main examination exists
            const examination = await this.examinations.get(examination_id, {attachments: false});
            // Check if the other examination exists
            const otherExamination = await this.examinations.get(other_examination_id, {attachments: false});
            const newDifference = {
                patient_id: patient._id,
                examination_id: examination._id,
                other_examination_id: otherExamination._id,
                note: note,
                _attachments: {
                    "difference.pdm": {
                        content_type: attachmentType,
                        data: new Blob([distanceMap], {type: attachmentType})
                    }
                }
            };
            if (_id) {
                newDifference._id = _id;
            }
            const writeStatus = await this.differences.post(newDifference);
            return await this.readDifference(writeStatus.id);
        } catch (err) {
            console.error(err);
        }
    }

    readDifference = async (differenceId) => {
        try {
            const difference = await this.differences.get(differenceId, {attachments: true, binary: true});
            const distanceMap = await difference._attachments["difference.pdm"].data.arrayBuffer();
            delete difference._attachments;
            difference["distanceMap"] = distanceMap;
            return difference;
        } catch (err) {
            console.error(err);
        }
    }

    listDifferences = async (patientId) => {
        try {
            const queryResult = await this.differences.find({
                selector: {patient_id: patientId},
                fields: ["_id", "_rev", "note", "patient_id", "examination_id", "other_examination_id"]
            });
            return queryResult.docs;
        } catch (err) {
            console.error(err);
        }
    }

    listAllDifferences = async (withAttachments = true) => {
        try {
            const docs = await this.differences.allDocs({include_docs: true, attachments: withAttachments});
            return docs.rows
                .map(({doc}) => doc) // Retrieve proper documents
                .filter(doc => !doc._id.startsWith("_design")); // Omitting query indices
        } catch (err) {
            console.error(err);
        }
    }

    deleteDifference = async (differenceId) => {
        try {
            const difference = await this.differences.get(differenceId, {attachments: false});
            return await this.differences.remove(difference);
        } catch (err) {
            console.error(err);
        }
    }

    /*
     * Settings CRUD
     */
    updateSettings = async (newSettings) => {
        try {
            const oldSettings = await this.configuration.get("settings");
            return await this.configuration.put({
                ...oldSettings,
                ...newSettings
            });
        } catch (err) {
            console.error(err);
        }
    }

    readSettings = async () => {
        try {
            return await this.configuration.get("settings");
        } catch (err) {
            // Write default settings and return them
            if (err.name === "not_found") {
                console.log("No default settings. Creating them!");
                const defaultSettings = DefaultSettings;
                defaultSettings._id = "settings";
                await this.configuration.put(defaultSettings);
                return await this.configuration.get("settings");
            } else {
                console.error(err);
            }
        }
    }

    onSettingsChange = (callback) => {
        return this.configuration.changes({
            since: 'now',
            live: true,
            include_docs: true,
            doc_ids: ['settings']
        }).on('change', function (change) {
            callback(change.doc)
        }).on('complete', function (info) {
            console.log(info)
        }).on('error', function (err) {
            console.error(err);
        });
    }

    /*
     * Import/Export
     */
    removeRevs = (docs) => {
        // Removes "_rev_ fields from the database entries.
        // Otherwise, it is not possible to import the data using bulkDocs().
        return docs.map(({_rev, ...rest}) => rest);
    }

    exportDatabase = async (withAttachments) => {
        try {
            const patients = this.removeRevs(await this.listPatients());
            const examinations = this.removeRevs(await this.listAllExaminations(withAttachments));
            const symmetries = this.removeRevs(await this.listAllSymmetries(withAttachments));
            const differences = this.removeRevs(await this.listAllDifferences(withAttachments));

            return {
                patients: patients,
                examinations: examinations,
                symmetries: symmetries,
                differences: differences
            };
        } catch (err) {
            console.error(err);
        }
        return {};
    }

    importPatients = async (patients) => {
        try {
            await this.patients.bulkDocs(patients);
        } catch (err) {
            console.error(err);
        }
    }

}

export const
    Database = new AppDatabase();