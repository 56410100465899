import {Container, Icon, Menu} from "semantic-ui-react";
import {ReactComponent as Logo} from "../images/logo.svg";
import {NavigationView} from "../GlobalStateProvider";
import {FormattedMessage} from "react-intl";
import React from "react";

export const Sidebar = ({
                            activeView,
                            activePatient,
                            scannerOnline,
                            showScannerTestingView,
                            computing,
                            onShowSelectedPatient,
                            onShowPatients,
                            onShowExaminations,
                            onShowScanning,
                            onShowSettings,
                            onShowScannerTesting
                        }) => {

    return (
        <div className="sidebar">
            <Menu pointing vertical fixed={'left'}
                  style={{"backgroundColor": "#def"}}>
                <Logo width={170} height={76} style={{display: "block", margin: "auto"}}/>
                <Menu.Item key="patients"
                           disabled={computing}
                           active={activeView === NavigationView.PATIENTS_LIST || activeView === NavigationView.PATIENT_DETAIL || activeView === NavigationView.PATIENT_ADD || activeView === NavigationView.PATIENT_EDIT}
                           onClick={onShowSelectedPatient}
                >
                    <Container
                        textAlign="left">
                        {activePatient === undefined
                            ? <Icon name="user secret" color="black" size="large"/>
                            : <Icon name="user circle" color="blue" size="large"/>}
                        <b>
                            {activePatient === undefined
                                ? (<FormattedMessage id="navigation.anonymous"
                                                     description="When no patient is selected"/>)
                                : activePatient.name + " " + activePatient.surname}
                        </b>
                    </Container>
                </Menu.Item>
                <Menu.Item disabled={computing}
                           active={activeView === NavigationView.PATIENTS_LIST || activeView === NavigationView.PATIENT_DETAIL || activeView === NavigationView.PATIENT_ADD || activeView === NavigationView.PATIENT_EDIT}
                           onClick={onShowPatients}>
                    <Icon name="group"/>
                    <FormattedMessage id="navigation.patients"
                                      description="Management of patients"/>
                </Menu.Item>
                <Menu.Item disabled={!activePatient || computing}
                           active={activeView === NavigationView.EXAMINATIONS_LIST || activeView === NavigationView.EXAMINATION_DETAIL}
                           onClick={onShowExaminations}>
                    <Icon name="heartbeat"/>
                    <FormattedMessage id="navigation.examinations"
                                      description="Management of examinations"/>
                </Menu.Item>
                <Menu.Item disabled={!activePatient || computing}
                           active={[NavigationView.SCANNING_VIEW, NavigationView.SCANNING_AUTO_START].includes(activeView)}
                           onClick={onShowScanning}>
                    <Icon name="camera retro" color={(scannerOnline ? "green" : "red")}/>
                    <FormattedMessage id="navigation.scanner"
                                      description="Scanning tool"/>
                </Menu.Item>
                <Menu.Item
                    disabled={computing}
                    active={activeView === NavigationView.SETTINGS}
                    onClick={onShowSettings}>
                    <Icon name="settings"/>
                    <FormattedMessage id="navigation.settings"
                                      description="Settings"/>
                </Menu.Item>
                {showScannerTestingView &&
                    <Menu.Item
                        active={activeView === NavigationView.SCANNER_TESTING}
                        onClick={onShowScannerTesting}
                    >
                        <Icon name="ambulance"/>
                        Scanner testing
                    </Menu.Item>
                }
            </Menu>
        </div>
    )

}