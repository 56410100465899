import {Divider, Form, Header, Icon, List} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {useGlobalState} from "../GlobalStateProvider";

export const DeveloperCorner = () => {

    const intl = useIntl();
    const [state, dispatch] = useGlobalState();

    return (
        <>
            <Divider horizontal>
                <Header as="h5">
                    <Icon name="camera retro"/>
                    <FormattedMessage id="settings.developer.maintenance" description="Scanner maintenance"/>
                </Header>
            </Divider>
            <Form>
                <Form.Checkbox
                    toggle
                    label={intl.formatMessage({
                        id: "settings.developer.toggle_scanner_testing",
                        description: "Show scanner testing view"
                    })}
                    checked={state.showScannerTestingView}
                    onChange={() => {
                        dispatch({showScannerTestingView: !state.showScannerTestingView});
                    }}
                />
            </Form>
            <Divider horizontal>
                <Header as="h5">
                    <Icon name="code"/>
                    <FormattedMessage id="settings.developer.build_info" description="Build information"/>
                </Header>
            </Divider>
            <List>
                <List.Item>
                    <List.Header>
                        Commit SHA
                    </List.Header>
                    {process.env.REACT_APP_COMMIT_SHA}
                </List.Item>
                <List.Item>
                    <List.Header>
                        Version tag
                    </List.Header>
                    {process.env.REACT_APP_TAG}
                </List.Item>
                <List.Item>
                    <List.Header>
                        Build date
                    </List.Header>
                    {process.env.REACT_APP_BUILD_DATE}
                </List.Item>
            </List>
        </>
    );

}