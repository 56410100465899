import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "semantic-ui-css/semantic.min.css";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {App} from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {GlobalStateProvider} from "./GlobalStateProvider";

ReactDOM.render(
    <React.StrictMode>
        <GlobalStateProvider>
            <App/>
        </GlobalStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
