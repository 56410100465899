import {Form, Segment} from "semantic-ui-react";
import React, {useState} from "react";
import {useIntl} from "react-intl";

export const ScanningForm = ({onSave, saveDisabled}) => {

    const intl = useIntl();
    const [name, setName] = useState("");
    const [note, setNote] = useState("");

    return (
        <Segment color={"green"}>
            <Form>
                <Form.Input
                    id="name"
                    label={intl.formatMessage({
                        id: "scanning.form.name",
                        description: "Scan name"
                    })}
                    placeholder={intl.formatMessage({
                        id: "scanning.form.name.placeholder",
                        description: "Placeholder for the scan name"
                    })}
                    value={name}
                    onChange={(_, {value: name}) => {
                        setName(name);
                    }}/>
                <Form.TextArea
                    id="note"
                    label={intl.formatMessage({
                        id: "scanning.form.note",
                        description: "Scan note"
                    })}
                    placeholder={intl.formatMessage({
                        id: "scanning.form.note.placeholder",
                        description: "Additional information about the scan"
                    })}
                    value={note}
                    onChange={(_, {value: note}) => {
                        setNote(note);
                    }}/>
                <Form.Group>
                    <Form.Button
                        primary
                        icon="save"
                        labelPosition="left"
                        content={intl.formatMessage({
                            id: "scanning.form.save_examination",
                            description: "Save result examination with the scan"
                        })}
                        disabled={saveDisabled}
                        onClick={() => onSave(name, note)}
                    />
                    <Form.Button
                        icon="erase"
                        labelPosition="left"
                        content={intl.formatMessage({
                            id: "scanning.form.clear",
                            description: "Clear form"
                        })}
                        onClick={() => {
                            setName("");
                            setNote("");
                        }}/>
                </Form.Group>
            </Form>
        </Segment>
    );

}
