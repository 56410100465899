import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const ExaminationMissing = ({onAddNewExamination, onImportExamination}) => {

    return (
        <Segment placeholder>
            <Header icon>
                <Icon name="heartbeat"/>
                <FormattedMessage id="examinations.list.no_entries"
                                  description="No registered examinations"/>

            </Header>
            <Segment.Inline>
                <Button icon
                        labelPosition="left"
                        primary
                        onClick={onAddNewExamination}
                >
                    <Icon name="add"/>
                    <FormattedMessage id="examinations.list.add_new"
                                      description="Add new examination"/>
                </Button>
                <Button icon
                        labelPosition="left"
                        floated="right"
                        onClick={onImportExamination}
                >
                    <Icon name="upload"/>
                    <FormattedMessage id="examinations.list.import"
                                      description="Import examination"/>
                </Button>
            </Segment.Inline>
        </Segment>
    );

}