import {PatientForm} from "./common/PatientForm";
import {useGlobalState} from "../GlobalStateProvider";
import {PatientEditHeader} from "./edit/PatientEditHeader";

export const PatientEditView = () => {

    const [state] = useGlobalState();
    const previewPatient = state.previewPatientId
        ? state.patients.find(patient => patient._id === state.previewPatientId)
        : null;

    return (
        <>
            <PatientEditHeader/>
            <PatientForm patient={previewPatient}/>
        </>
    );
}