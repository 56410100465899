import * as React from "react";
import {Button, Form, Icon} from "semantic-ui-react";
import {ConnectionAddress} from "./ConnectionAddress";
import {ConnectionStatus, ConnectionTestResult} from "./ConnectionTestResult";
import {ScannerController} from "@iniphy/scanner-controller";
import {FormattedMessage} from "react-intl";
import {Database} from "../app/AppDatabase";
import {withGlobalState} from "../GlobalStateProvider";

class ConnectionSetupImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            connectionStatus: ConnectionStatus.Idle,
        };
    }

    testConnection = () => {
        const {state} = this.props;
        const address = state.settings;
        // Test if scanner is available
        const controller = new ScannerController(address.url, address.port, address.secureConnection);
        controller.checkConnection().then(async () => {
            this.setState({
                connectionStatus: ConnectionStatus.Online,
            });
            this.props.onSuccess();
            const newSettings = {
                ...state.settings,
                url: address.url,
                port: address.port,
                secureConnection: address.secureConnection
            };
            await Database.updateSettings(newSettings);
        }, error => {
            this.setState({
                connectionStatus: ConnectionStatus.Offline,
            });
            this.props.onError(error);
        });
    }

    render() {
        const {dispatch, state} = this.props;
        return (
            <Form>
                <Form.Group>
                    <Form.Field>
                        <Button icon labelPosition='left'
                                onClick={this.testConnection}
                                color='blue'>
                            <Icon name='wifi'/>
                            <FormattedMessage id='settings.connection.test_connection'
                                              description='Perform the connection test'/>
                        </Button>
                    </Form.Field>
                    <ConnectionAddress
                        {...state.settings}
                        onUpdate={(connection) => {
                            const newSettings = {...state.settings};
                            newSettings.url = connection.url;
                            newSettings.port = connection.port;
                            newSettings.secureConnection = connection.secureConnection;
                            dispatch({settings: newSettings});
                        }}/>
                </Form.Group>
                <Form.Field>
                    <ConnectionTestResult connectionStatus={this.state.connectionStatus}/>
                </Form.Field>
            </Form>
        );
    }
}

export const ConnectionSetup = withGlobalState(ConnectionSetupImpl);
