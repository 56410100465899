import React from "react";
import {Button, Header, Icon, List, Modal} from "semantic-ui-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {format} from "date-fns";


class ExaminationRemoveConfirmationImpl extends React.Component {

    render() {
        const {open, examination, onCancel, onRemove, intl} = this.props;
        const date = examination.date;
        return (
            <Modal open={open} size="tiny">
                <Modal.Header>
                    <Icon name="warning sign"/>
                    <FormattedMessage
                        id="examinations.confirmation.header"
                        description="Remove examination window header"/>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header as={"h4"}>
                            <FormattedMessage
                                id="examinations.confirmation.are_you_sure"
                                description="Are you sure about the removal?"
                                values={{name: examination.name}}/>
                        </Header>
                        <FormattedMessage
                            id="examinations.confirmation.details"
                            description="You will also remove"/>
                        <List as="ul">
                            <List.Item as="li">
                                <FormattedMessage
                                    id="examinations.confirmation.1_scan"
                                    description="Scan information"
                                    values={{
                                        date: format(new Date(date), "dd/MM/yyyy"),
                                        time: format(new Date(date), "HH:mm:ss")
                                    }}/>
                            </List.Item>
                            <List.Item as="li">
                                <FormattedMessage
                                    id="examinations.confirmation.maybe_symmetry"
                                    description="Number of symmetries"
                                    values={{number: examination.symmetry ? 1 : 0}}/>
                            </List.Item>
                            <List.Item as="li">
                                <FormattedMessage
                                    id="examinations.confirmation.n_differences"
                                    description="Number of differences"
                                    values={{number: 0}}/>
                            </List.Item>
                        </List>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={intl.formatMessage({
                            id: "examinations.confirmation.cancel",
                            description: "Cancel the removal"
                        })}
                        labelPosition="left"
                        icon="close"
                        onClick={onCancel}
                    />
                    <Button
                        negative
                        content={intl.formatMessage({
                            id: "examinations.confirmation.remove",
                            description: "Finish the removal"
                        })}
                        labelPosition="left"
                        icon='trash'
                        onClick={() => onRemove(examination._id)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

}

export const ExaminationRemoveConfirmation = injectIntl(ExaminationRemoveConfirmationImpl);