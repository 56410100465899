import {Button, Dropdown, Icon} from "semantic-ui-react";
import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";

export const PatientDetailsAction = ({onGoToPreviousView, onEditPatient, onRemovePatient, onExaminePatient}) => {

    const intl = useIntl();

    return (
        <>
            <Button.Group floated="right">
                <Button
                    content={intl.formatMessage({id: "patients.details.back"})}
                    icon="backward"
                    labelPosition="left"
                    onClick={onGoToPreviousView}
                />
                <Dropdown
                    className="button icon"
                    floating
                    trigger={<></>}
                    options={[
                        {
                            key: "edit",
                            icon: "edit",
                            text: intl.formatMessage({id: "patients.details.edit"}),
                            value: "edit",
                            onClick: onEditPatient
                        },
                        {
                            key: "remove",
                            icon: "trash",
                            text: intl.formatMessage({id: "patients.details.remove"}),
                            value: "remove",
                            onClick: onRemovePatient
                        }
                    ]}
                />
            </Button.Group>
            <Button icon
                    labelPosition="left"
                    floated="right"
                    positive
                    style={{"marginRight": "15px"}}
                    onClick={onExaminePatient}>
                <Icon name="sign-in"/>
                <FormattedMessage id="patients.details.select"/>
            </Button>
        </>
    );

}