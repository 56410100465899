import {Divider, Header, Icon, List} from "semantic-ui-react";
import {Centered} from "../../common/Centered";
import {FormattedMessage} from "react-intl";
import * as React from "react";

export const PatientExaminationData = ({patient}) => {

    const iconStyle = {marginRight: "5px"};

    return (
        <>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="stethoscope"/>
                    <FormattedMessage id="patients.details.examination_data"/>
                </Header>
            </Divider>
            <Centered>
                <List horizontal relaxed>
                    <List.Item>
                        <List.Icon name="heartbeat" style={iconStyle}/>
                        <FormattedMessage id="patients.details.n_examinations"
                                          values={{number: patient.num_examinations}}/>
                    </List.Item>
                    <List.Item>
                        <Icon name="adjust" style={iconStyle}/>
                        <FormattedMessage id="patients.details.n_symmetries"
                                          values={{number: patient.num_symmetries}}/>
                    </List.Item>
                    <List.Item>
                        <Icon name="clone outline" style={iconStyle}/>
                        <FormattedMessage id="patients.details.n_differences"
                                          values={{number: patient.num_differences}}/>
                    </List.Item>
                </List>
            </Centered>
        </>
    );
}