import React from "react";
import {Button, Dimmer, Icon, Loader, Modal} from "semantic-ui-react";
import {checkIsServerOnline, updatePatientNumsBy} from "../../common/Helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import {MeshProcessing} from "@iniphy/mesh-processing";
import {Database} from "../../app/AppDatabase";
import {ScannerController} from "@iniphy/scanner-controller";
import {withGlobalState} from "../../GlobalStateProvider";
import {DifferenceComparisonTable} from "./comparison/DifferenceComparisonTable";


class ComparisonListImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
        };
    }

    computeDifference = async () => {
        const {examination, dispatch, state, onClose} = this.props;
        const settings = state.settings;
        const {selected} = this.state;
        dispatch({computing: true});
        const baseExaminationId = examination._id;
        const otherExaminationId = selected;
        try {
            const otherExamination = await Database.readExamination(otherExaminationId);
            let distanceMap;
            const useScannerForAnalysis = settings.performAnalysisOnScanner && await checkIsServerOnline(settings.url, settings.port);
            if (useScannerForAnalysis) {
                const controller = new ScannerController(settings.url, settings.port, settings.secureConnection);
                const hasBaseScan = await controller.hasScan(baseExaminationId);
                const hasOtherScan = await controller.hasScan(otherExaminationId);
                if (!hasBaseScan) {
                    await controller.uploadScan(baseExaminationId, examination.mesh);
                }
                if (!hasOtherScan) {
                    await controller.uploadScan(otherExaminationId, otherExamination.mesh);
                }
                distanceMap = await controller.analyseDifference(baseExaminationId, otherExaminationId);
            } else {
                const meshProcessing = new MeshProcessing();
                distanceMap = await meshProcessing.differenceAnalysis(otherExamination.mesh, examination.mesh);
            }
            const difference = {
                patient_id: examination.patient_id,
                examination_id: examination._id,
                other_examination_id: otherExamination._id,
                note: "",
                distanceMap: distanceMap
            };
            const newDifference = await Database.createDifference(difference);
            const updatedDifference = state.differences;
            updatedDifference.push(newDifference);
            dispatch({
                computing: false,
                differences: updatedDifference,
                patients: updatePatientNumsBy(state.patients, state.activePatientId, 0, 0, 1)
            });
            onClose()
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const {examination, examinations, onClose, open, intl, state} = this.props;
        const {selected} = this.state;

        return (
            <Modal open={open}>
                <Modal.Header>
                    <Icon name="clone outline"/>
                    <FormattedMessage id="examinations.comparison.header"/>
                </Modal.Header>
                <Modal.Content>
                    <DifferenceComparisonTable
                        examination={examination}
                        items={examinations}
                        onSelectDifference={(id) => this.setState({selected: id})}
                        selectedDifferenceId={this.state.selected}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={intl.formatMessage({id: "examinations.comparison.cancel"})}
                        labelPosition="left"
                        icon="close"
                        onClick={onClose}
                    />
                    <Button
                        positive
                        content={intl.formatMessage({id: "examinations.comparison.compute"})}
                        labelPosition="left"
                        icon="calculator"
                        disabled={!selected}
                        onClick={this.computeDifference}
                    />
                </Modal.Actions>
                <Dimmer inverted active={state.computing}>
                    <Loader size="massive">
                        <FormattedMessage id="examinations.comparison.computing_difference"/>
                    </Loader>
                </Dimmer>
            </Modal>
        )
    }
}

export const DifferenceComparisonView = injectIntl(withGlobalState(ComparisonListImpl));
