import {Icon, Message} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const ScanningProgress = ({scanning, analysingSymmetry}) => {
    return (
        <>
            <Message icon
                     hidden={!scanning}>
                <Icon name='circle notched' loading/>
                <Message.Content>
                    <Message.Header>
                        <FormattedMessage id="scanning.in_progress"
                                          description="Scanning is in progress"/>
                    </Message.Header>
                    <FormattedMessage id="scanning.use_this_time"
                                      description="Use the time of scanning to do something"/>
                </Message.Content>
            </Message>
            <Message icon
                     hidden={!analysingSymmetry}>
                <Icon name='adjust' loading/>
                <Message.Content>
                    <Message.Header>
                        <FormattedMessage id="scanning.in_progress.symmetry"
                                          description="Symmetry analysis is in progress"/>
                    </Message.Header>
                    <FormattedMessage id="scanning.use_this_time.symmetry"
                                      description="Use the time of the analysis to do something"/>
                </Message.Content>
            </Message>
        </>
    )
}