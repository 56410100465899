import React, {useEffect} from "react";
import {DetailsLayout} from "../DetailsLayout";
import {Details} from "./Details";
import {Database} from "../../app/AppDatabase";
import {useGlobalState} from "../../GlobalStateProvider";
import {ScanViewer} from "../../common/ScanViewer";
import {formatScanDatetime} from "../../common/Helpers";
import {DateFnsLocale} from "../../app/Parameters";

export const View = ({examination}) => {

    const [state, dispatch] = useGlobalState();

    useEffect(() => {
        async function loadMissingMesh() {
            // Read scan mesh of an examination if not available
            if (!examination.mesh) {
                const updatedExaminations = await Database.readExamination(examination._id);
                dispatch({
                    examinations: state.examinations.map(entry => entry._id === examination._id ? updatedExaminations : entry)
                });
            }
        }

        loadMissingMesh().then()
    }, [examination, state, dispatch])

    return <DetailsLayout
        details={<Details examination={examination}/>}
        scanViewer={<ScanViewer mesh={examination.mesh}
                                label={formatScanDatetime(examination.date, DateFnsLocale[state.settings.language])}/>}
    />;

}