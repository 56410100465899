import {Button, Icon} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const PatientListActions = ({onAddNewPatient}) => {
    return (
        <Button icon
                labelPosition="left"
                floated="right"
                primary
                onClick={onAddNewPatient}
        >
            <Icon name="add user"/>
            <FormattedMessage
                id="patients.list.add_new"
                description="Add new patient"/>
        </Button>
    );
}