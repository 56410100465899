import * as React from "react";
import {Form} from "semantic-ui-react";
import {ConnectionAliveInterval, SymmetryReflectionAxis} from "../app/Parameters";
import {injectIntl} from "react-intl";
import {Database} from "../app/AppDatabase";
import {withGlobalState} from "../GlobalStateProvider";

export class GeneralSettingsImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            duringSave: false
        };
    }

    handleChange = async (_, {id, value}) => {
        const {dispatch, state} = this.props;
        this.setState({duringSave: true});
        const newSettings = {...state.settings, [id]: value};
        await Database.updateSettings(newSettings);
        this.setState({duringSave: false}, () => {
            dispatch({settings: newSettings});
        });
    }

    handleBinaryOptionChange = async (_, {id}) => {
        const {dispatch, state} = this.props;
        const newSettings = {...state.settings, [id]: !state.settings[id]};
        await Database.updateSettings(newSettings);
        dispatch({settings: newSettings});
    }

    render() {
        const {duringSave} = this.state;
        const {intl, state} = this.props;
        const languages = [
            {key: 'pl', text: 'Polski', value: 'pl', flag: 'pl'},
            {key: 'en', text: 'English', value: 'en', flag: 'gb'},
        ];
        const connectionAliveIntervals = [
            {
                key: '10_seconds',
                text: intl.formatMessage({
                    id: 'settings.general.n_seconds',
                }, {seconds: 10}),
                value: ConnectionAliveInterval.Seconds10
            },
            {
                key: '30_seconds',
                text: intl.formatMessage({
                    id: 'settings.general.n_seconds',
                }, {seconds: 30}),
                value: ConnectionAliveInterval.Seconds30
            },
            {
                key: '1_minute',
                text: intl.formatMessage({
                    id: 'settings.general.1_minute',
                }),
                value: ConnectionAliveInterval.Minute1
            },
            {
                key: '5_minute',
                text: intl.formatMessage({
                    id: 'settings.general.n_minutes',
                }, {minutes: 5}),
                value: ConnectionAliveInterval.Minutes5
            }
        ];
        const symmetryReflectionAxes = [
            {
                key: 'axis_auto',
                text: intl.formatMessage({
                    id: "settings.general.auto_reflection_axis",
                    description: "Automatic reflection axis"
                }),
                value: SymmetryReflectionAxis.AUTO
            },
            {key: 'axis_x', text: 'X', value: SymmetryReflectionAxis.X},
            {key: 'axis_y', text: 'Y', value: SymmetryReflectionAxis.Y},
            {key: 'axis_z', text: 'Z', value: SymmetryReflectionAxis.Z},
        ];

        return (
            <Form>
                <Form.Select
                    id="language"
                    label={intl.formatMessage({
                        id: 'settings.general.app_language',
                        description: 'Language of the application'
                    })}
                    options={languages}
                    value={state.settings.language}
                    onChange={this.handleChange}
                />
                <Form.Select
                    id="connectionAliveInterval"
                    label={intl.formatMessage({
                        id: 'settings.general.connection_alive_check',
                        description: 'Checking if connection to scanner is alive'
                    })}
                    options={connectionAliveIntervals}
                    value={state.settings.connectionAliveInterval}
                    onChange={this.handleChange}
                />
                <Form.Select
                    id="symmetryReflectionAxis"
                    label={intl.formatMessage({
                        id: 'settings.general.reflection_axis',
                        description: 'Reflection axis for the symmetry analysis'
                    })}
                    options={symmetryReflectionAxes}
                    value={state.settings.symmetryReflectionAxis}
                    onChange={this.handleChange}
                />
                <Form.Input
                    disabled={duringSave}
                    id="maximalRangeOfVisibleChangesOfSymmetry"
                    label={intl.formatMessage({
                        id: 'settings.general.maximal_range_symmetry',
                        description: 'Maximal range of visible differences in scan symmetry (in cm)'
                    }, {distance: state.settings.maximalRangeOfVisibleChangesOfSymmetry})}
                    min={1}
                    max={30}
                    name='hide'
                    step={1}
                    type='range'
                    value={state.settings.maximalRangeOfVisibleChangesOfSymmetry}
                    onChange={this.handleChange}
                />
                <Form.Input
                    disabled={duringSave}
                    id="maximalRangeOfVisibleChangesOfDifference"
                    label={intl.formatMessage({
                        id: 'settings.general.maximal_range_difference',
                        description: 'Maximal range of visible differences in scans difference (in cm)'
                    }, {distance: state.settings.maximalRangeOfVisibleChangesOfDifference})}
                    min={1}
                    max={30}
                    name='hide'
                    step={1}
                    type='range'
                    value={state.settings.maximalRangeOfVisibleChangesOfDifference}
                    onChange={this.handleChange}
                />
                <Form.Checkbox
                    toggle
                    id="automaticSymmetryAnalysis"
                    label={intl.formatMessage({
                        id: "settings.general.automatic_symmetry",
                        description: "Automatic symmetry analysis"
                    })}
                    checked={state.settings.automaticSymmetryAnalysis}
                    onChange={this.handleBinaryOptionChange}
                />
                <Form.Checkbox
                    toggle
                    id="symmetryIsPrimary"
                    label={intl.formatMessage({
                        id: "settings.general.symmetry_primary_view",
                        description: "Symmetry is the primary view"
                    })}
                    checked={state.settings.symmetryIsPrimary}
                    onChange={this.handleBinaryOptionChange}
                />
                <Form.Checkbox
                    toggle
                    id="startScanningOnNewExamination"
                    label={intl.formatMessage({
                        id: "settings.general.start_scanning_on_new_examination",
                        description: "Start scanning on new examination"
                    })}
                    checked={state.settings.startScanningOnNewExamination}
                    onChange={this.handleBinaryOptionChange}
                />
                <Form.Checkbox
                    toggle
                    id="performAnalysisOnScanner"
                    label={intl.formatMessage({
                        id: "settings.general.perform_analysis_on_scanner",
                        description: "Perform analyses (symmetry/difference) on the scanner"
                    })}
                    checked={state.settings.performAnalysisOnScanner}
                    onChange={this.handleBinaryOptionChange}
                />
            </Form>
        );
    }
}

export const GeneralSettings = injectIntl(withGlobalState(GeneralSettingsImpl));
