import React from 'react';
import {MeshViewerGroup} from '@iniphy/mesh-viewer';
import {AppContent} from "./app/AppContent";
import {IntlProvider} from "react-intl";

import messages_pl from "./translations/pl.json";
import messages_en from "./translations/en.json";
import {Database} from "./app/AppDatabase";
import {ScannerController} from "@iniphy/scanner-controller";
import {bySurnameNameSorter} from "./common/Helpers";
import {withGlobalState} from "./GlobalStateProvider";

const messages = {
    'pl': messages_pl,
    'en': messages_en
};

class AppImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scannerOnline: false
        };

        this.onSettingsChangeListener = null;
        this.onPatientsChangeListener = null;
    }

    reloadAppSettings = (newSettings) => {
        this.props.dispatch({
            settings: newSettings
        });
    }

    reloadPatients = () => {
        Database.listPatients(true).then(newPatients => {
            this.props.dispatch({
                patients: newPatients.sort(bySurnameNameSorter)
            });
        });
    }

    testConnection = () => {
        const settings = this.props.state.settings;
        const controller = new ScannerController(settings.url, settings.port, settings.secureConnection);
        controller.checkConnection().then(() => {
            this.setState({
                scannerOnline: true
            });
        }, () => {
            this.setState({
                scannerOnline: false
            });
        });
        // Apparently, setInterval() has a tendency to stack messages.
        // Also, setTimeout() works great here, because connectionAliveInterval can change anytime!
        // Info and solution: https://stackoverflow.com/questions/6685396/execute-the-setinterval-function-without-delay-the-first-time
        setTimeout(this.testConnection, settings.connectionAliveInterval);
    }

    componentDidMount() {
        // Request persistent storage for site
        // More info here: https://web.dev/persistent-storage/
        if (navigator.storage && navigator.storage.persist) {
            navigator.storage.persist().then(isPersisted => {
                console.log(`Persisted storage granted: ${isPersisted}`);
            });
        }
        // More info here: https://web.dev/storage-for-the-web/
        // Disclaimer: this works great on Chrome only for the moment
        if (navigator.storage && navigator.storage.estimate) {
            navigator.storage.estimate().then(quota => {
                // quota.usage -> Number of bytes used.
                // quota.quota -> Maximum number of bytes available.
                const percentageUsed = ((quota.usage / quota.quota) * 100).toFixed(2);
                console.log(`You've used ${percentageUsed}% of the available storage.`);
                const remaining = quota.quota - quota.usage;
                console.log(`You can write up to ${remaining / 1024 / 1024 / 1024} more gigabytes (GB).`);
            });
        }
        Database.readSettings().then(settings => {
            this.reloadAppSettings(settings);
            this.testConnection();
        }, err => {
            console.error(err);
        });
        this.reloadPatients();
        // Track changes to settings of the application
        this.onSettingsChangeListener = Database.onSettingsChange(this.reloadAppSettings);
    }

    componentWillUnmount() {
        this.onSettingsChangeListener.cancel();
    }

    render() {
        const {scannerOnline} = this.state;
        const {state} = this.props;
        if (!state.settings || !state.patients) return null;
        // Find system language if needed
        if (state.settings.language === "default") {
            const browserDefaultLanguageCode = navigator.language.split(/[-_]/)[0];
            // Check if translations for the default (system) language are available
            if (Object.keys(messages).includes(browserDefaultLanguageCode)) {
                state.settings.language = browserDefaultLanguageCode;
            } else {
                // Fallback language
                state.settings.language = "pl";
            }
        }
        return (
            <IntlProvider locale={state.settings.language}
                          messages={messages[state.settings.language]}>
                <MeshViewerGroup>
                    <AppContent scannerOnline={scannerOnline}/>
                </MeshViewerGroup>
            </IntlProvider>
        );
    }
}

export const App = withGlobalState(AppImpl);
