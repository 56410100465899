import React from "react";
import {Segment} from "semantic-ui-react"
import {NavigationView, useGlobalState} from "../GlobalStateProvider";
import {Sidebar} from "../sidebar/Sidebar"
import {Navigation} from "../Navigation";

const AppContentImpl = ({scannerOnline}) => {

    const [state, dispatch] = useGlobalState();

    const activeView = state.activeView;
    const activePatient = state.patients.find(patient => patient._id === state.activePatientId);
    return (<>
        <Sidebar
            activeView={activeView}
            activePatient={activePatient}
            scannerOnline={scannerOnline}
            showScannerTestingView={state.showScannerTestingView}
            computing={state.computing}
            onShowSelectedPatient={() => {
                if (state.activePatientId) {
                    dispatch({
                        activeView: NavigationView.PATIENT_DETAIL, previewPatientId: state.activePatientId,
                    });
                } else {
                    dispatch({activeView: NavigationView.PATIENTS_LIST});
                }
            }}
            onShowPatients={() => dispatch({activeView: NavigationView.PATIENTS_LIST})}
            onShowExaminations={() => dispatch({activeView: NavigationView.EXAMINATIONS_LIST})}
            onShowScanning={() => dispatch({activeView: NavigationView.SCANNING_VIEW})}
            onShowSettings={() => dispatch({activeView: NavigationView.SETTINGS})}
            onShowScannerTesting={() => dispatch({activeView: NavigationView.SCANNER_TESTING})}
        />
        <div className="content" style={{marginLeft: "230px"}}>
            <Segment basic padded>
                <Navigation
                    activeView={activeView}
                />
            </Segment>
        </div>
    </>)
}

export const AppContent = React.memo(AppContentImpl);
