import React from "react";
import {Accordion, Button, Header, Icon} from "semantic-ui-react";
import {ServerErrors} from "../common/ServerErrors";
import {OperationMessage, OperationStatus} from "../common/OperationMessage";
import {ConnectionSetup} from "./ConnectionSetup";
import {DatabaseImportExport} from "./DatabaseImportExport";
import {GeneralSettings} from "./GeneralSettings";
import {FormattedMessage, injectIntl} from "react-intl";
import {DefaultSettings} from "../app/Parameters";
import {Database} from "../app/AppDatabase";
import {DeveloperCorner} from "./DeveloperCorner";


class SettingsViewImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            settingsUpdateStatus: OperationStatus.None,
            errors: []
        };
    }

    onConnectionSuccess = () => {
        this.setState({
            settingsUpdateStatus: OperationStatus.Success,
            errors: []
        });
    }

    onConnectionError = (errors) => {
        this.setState({
            settingsUpdateStatus: OperationStatus.None,
            errors: errors
        })
    }

    resetToDefaultSettings = async () => {
        await Database.updateSettings(DefaultSettings);
    }

    render() {
        const {intl} = this.props;
        const connectionSetup = (
            <ConnectionSetup onSuccess={this.onConnectionSuccess}
                             onError={this.onConnectionError}/>);
        return (
            <>
                <Button icon
                        labelPosition="left"
                        floated="right"
                        onClick={this.resetToDefaultSettings}>
                    <Icon name="repeat"/>
                    <FormattedMessage
                        id="settings.reset_default"
                        description="Reset to default settings"
                    />
                </Button>
                <Header as="h2" style={{marginTop: "0px"}}>
                    <Icon name="settings"/>
                    <Header.Content>
                        <FormattedMessage
                            id="settings.header"
                        />
                    </Header.Content>
                </Header>
                <Accordion fluid styled exclusive={false} defaultActiveIndex={[0]} panels={[
                    {
                        key: 'panel-1',
                        title: intl.formatMessage({
                            id: 'settings.section.general',
                            description: 'General settings'
                        }),
                        content: {content: <GeneralSettings/>}
                    },
                    {
                        key: 'panel-2',
                        title: intl.formatMessage({
                            id: 'settings.section.connection',
                            description: 'Connection setup'
                        }),
                        content: {content: connectionSetup}
                    },
                    {
                        key: 'panel-3',
                        title: intl.formatMessage({
                            id: "settings.section.database",
                            description: "Database management"
                        }),
                        content: {content: <DatabaseImportExport/>}
                    },
                    {
                        key: "panel-4",
                        title: intl.formatMessage({
                            id: "settings.developer.corner",
                            description: "Developer's corner"
                        }),
                        content: {content: <DeveloperCorner/>}
                    }
                ]}>

                </Accordion>
                <ServerErrors errors={this.state.errors}/>
                <OperationMessage
                    header={{
                        success: intl.formatMessage({
                            id: 'settings.successful_save.header',
                            description: 'Successful save to the database'
                        }),
                    }}
                    content={{
                        success: intl.formatMessage({
                            id: 'settings.successful_save.content',
                            description: 'New settings were successfully saved to the database'
                        }),
                    }}
                    status={this.state.settingsUpdateStatus}
                    onDismiss={() => this.setState({settingsUpdateStatus: OperationStatus.None})}
                />
            </>
        );
    }

}

export const SettingsView = injectIntl(SettingsViewImpl);
