import {PatientForm} from "./common/PatientForm";
import {PatientCreateHeader} from "./create/PatientCreateHeader";

export const PatientCreateView = () => {

    return (
        <>
            <PatientCreateHeader/>
            <PatientForm/>
        </>
    );

}