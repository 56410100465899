import {Grid} from "semantic-ui-react";
import * as React from "react";
import {PatientRemoveConfirmation} from "./details/PatientRemoveConfirmation";
import {NavigationView, useGlobalState} from "../GlobalStateProvider";
import {Database} from "../app/AppDatabase";
import {byDateSorter, formatScanDatetime} from "../common/Helpers";
import {ScanViewer} from "../common/ScanViewer";
import {useEffect, useState} from "react";
import {PatientDetailsAction} from "./details/PatientDetailsAction";
import {PatientPersonalData} from "./details/PatientPersonalData";
import {PatientExaminationData} from "./details/PatientExaminationInformation";
import {PatientNote} from "./details/PatientNote";
import {DateFnsLocale} from "../app/Parameters";
import {PatientDetailsHeader} from "./details/PatientDetailsHeader";
import {useIntl} from "react-intl";
import {compareDesc, parseISO} from "date-fns";

export const PatientDetailsView = () => {

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [lastExamination, setLastExamination] = useState(null);
    const [state, dispatch] = useGlobalState();
    const intl = useIntl();

    const selectPatient = async () => {
        try {
            dispatch({
                activeView: NavigationView.EXAMINATIONS_LIST,
                activePatientId: patient._id,
                activeExaminationId: null,
                examinations: (await Database.listExaminations(patient._id)).sort(byDateSorter),
                symmetries: await Database.listSymmetries(patient._id),
                differences: await Database.listDifferences(patient._id)
            });
        } catch (err) {
            console.error(err);
        }
    }

    const editPatient = () => {
        dispatch({
            activeView: NavigationView.PATIENT_EDIT,
            previewPatientId: patient._id
        });
    }

    const goBack = () => {
        dispatch({
            activeView: NavigationView.PATIENTS_LIST,
            previewPatientId: undefined
        });
    }

    const startRemovingPatient = () => {
        setConfirmationOpen(true);
    }

    const cancelRemovingPatient = () => {
        setConfirmationOpen(false);
    }

    const removePatient = (patientId) => {
        Database.deletePatient(patientId).then(() => {
            dispatch({
                activeView: NavigationView.PATIENTS_LIST,
                activePatientId: patientId === state.activePatientId ? null : state.activePatientId,
                previewPatientId: null
            });
        });
    }

    useEffect(() => {
        async function loadMesh() {
            const examinations = await Database.listExaminations(state.previewPatientId);
            if (examinations.length > 0) {
                // Find the latest examination date
                const dates = examinations.map(item => parseISO(item.date));
                dates.sort(compareDesc);
                let foundExamination = examinations.find(item => item.date === dates[0].toISOString())
                if (foundExamination === undefined) {
                    foundExamination = examinations[0];
                }
                // Read the latest examination mesh data
                const lastExamination = await Database.readExamination(foundExamination._id);
                setLastExamination(lastExamination);
            } else {
                setLastExamination(null);
            }
        }

        loadMesh().then();
    }, [setLastExamination, state.previewPatientId]);

    const patient = state.patients.find(patient => patient._id === state.previewPatientId);
    return (
        <>
            <PatientDetailsAction
                onGoToPreviousView={goBack}
                onEditPatient={editPatient}
                onRemovePatient={startRemovingPatient}
                onExaminePatient={selectPatient}
            />
            <PatientDetailsHeader patient={patient}/>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <PatientPersonalData patient={patient} locale={DateFnsLocale[state.settings.language]}/>
                        <PatientExaminationData patient={patient}/>
                        {patient.about !== "" && <PatientNote patient={patient}/>}
                        <PatientRemoveConfirmation open={confirmationOpen}
                                                   patient={patient}
                                                   onCancel={cancelRemovingPatient}
                                                   onRemove={removePatient}/>

                    </Grid.Column>
                    <Grid.Column>
                        <ScanViewer
                            mesh={lastExamination ? lastExamination.mesh : undefined}
                            dimmed={!lastExamination}
                            dimmedMessage={intl.formatMessage({id: "patients.details.perform_first_examination"})}
                            label={lastExamination
                                && intl.formatMessage(
                                    {id: "patients.details.last_scan_from"},
                                    {date: formatScanDatetime(lastExamination.date, DateFnsLocale[state.settings.language])})}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );

}
