import {Header, Icon} from "semantic-ui-react";
import * as React from "react";
import {FormattedMessage} from "react-intl";

export const PatientCreateHeader = () => {

    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="add user"/>
            <Header.Content>
                <FormattedMessage id={"patients.create.new_patient"}/>
                <Header.Subheader>
                    <FormattedMessage id={"patients.create.create_new_patient"}/>
                </Header.Subheader>
            </Header.Content>
        </Header>
    );
}