import {Grid} from "semantic-ui-react";

export const Centered = (props) => {

    return (
        <Grid>
            <Grid.Column textAlign={"center"}>
                {props.children}
            </Grid.Column>
        </Grid>
    )

}