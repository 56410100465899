import {Header, Icon} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const ScanningHeader = () => {

    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="camera retro"/>
            <Header.Content>
                <FormattedMessage id="scanning.header"
                                  description="Title of the scanning view"/>
            </Header.Content>
        </Header>
    );
}
