import {NavigationView} from "./GlobalStateProvider";
import {PatientDetailsView} from "./patients/PatientDetailsView";
import {PatientListView} from "./patients/PatientListView";
import {ExaminationListView} from "./examinations/ExaminationListView";
import {ExaminationDetails} from "./examinations/ExaminationDetails";
import {ScanningView} from "./scanning/ScanningView";
import {SettingsView} from "./settings/SettingsView";
import React from "react";
import {PatientCreateView} from "./patients/PatientCreateView";
import {PatientEditView} from "./patients/PatientEditView";
import {ScannerTestingView} from "./maintenance/ScannerTestingView";

export const Navigation = ({activeView}) => {

    const selectView = (activeView) => {
        switch (activeView) {
            case NavigationView.PATIENTS_LIST:
                return <PatientListView/>;
            case NavigationView.PATIENT_DETAIL:
                return <PatientDetailsView/>;
            case NavigationView.PATIENT_ADD:
                return <PatientCreateView/>;
            case NavigationView.PATIENT_EDIT:
                return <PatientEditView/>;
            case NavigationView.EXAMINATIONS_LIST:
                return <ExaminationListView/>;
            case NavigationView.EXAMINATION_DETAIL:
                return <ExaminationDetails/>;
            case NavigationView.SCANNING_VIEW:
                return <ScanningView startOnNewExamination={false}/>;
            case NavigationView.SCANNING_AUTO_START:
                return <ScanningView startOnNewExamination={true}/>;
            case NavigationView.SETTINGS:
                return <SettingsView/>;
            case NavigationView.SCANNER_TESTING:
                return <ScannerTestingView/>
            default:
                return <PatientListView/>
        }
    }

    return selectView(activeView);
}