import {Button, Dropdown} from "semantic-ui-react";
import React from "react";
import {useIntl} from "react-intl";

export const ExaminationListActions = ({onAddNewExamination, onImportExamination}) => {

    const intl = useIntl();

    return (
        <Button.Group
            primary
            floated="right">
            <Button
                content={intl.formatMessage({
                    id: "examinations.list.add_new",
                    description: "Add new examination"
                })}
                icon="add"
                labelPosition="left"
                onClick={onAddNewExamination}
            />
            <Dropdown
                className='button icon'
                floating
                trigger={<></>}
                options={[
                    {
                        key: 'import',
                        icon: 'upload',
                        text: intl.formatMessage({
                            id: "examinations.list.import",
                            description: "Import examination"
                        }),
                        value: 'import',
                        onClick: onImportExamination
                    }
                ]}
            />
        </Button.Group>
    );
}
