import {Icon, Table} from "semantic-ui-react";
import {LocalSearchInput} from "../../sidebar/LocalSearchInput";
import {FormattedMessage} from "react-intl";
import {TableCellEllipsis} from "../../common/TableCellEllipsis";
import React from "react";
import {withPagination} from "../../common/withPagination";
import {TableRowRibbon} from "../../common/TableRowRibbon";

const PatientTableImpl = ({
                              onSearchQueryChange,
                              onPatientClick,
                              activePatientId,
                              items: filteredPatients
                          }) => {

    let currentLetter = '';
    let ribbon = null;

    return (
        <>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>
                        <LocalSearchInput onSearchQueryChange={onSearchQueryChange}
                                          noResults={filteredPatients.length === 0}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="patients.list.about"
                            description="About patient"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name='heartbeat'/>
                        <FormattedMessage
                            id="patients.list.examinations"
                            description="Number of examinations"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name='adjust'/>
                        <FormattedMessage
                            id="patients.list.symmetries"
                            description="Number of symmetries"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name='clone outline'/>
                        <FormattedMessage
                            id="patients.list.differences"
                            description="Number of differences"
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {filteredPatients.map(({
                                           _id,
                                           name,
                                           surname,
                                           about,
                                           num_examinations,
                                           num_symmetries,
                                           num_differences
                                       }) => {
                    if (surname && currentLetter !== surname[0].toUpperCase()) {
                        currentLetter = surname[0].toUpperCase()
                        ribbon = <TableRowRibbon text={surname[0].toUpperCase()}/>
                    } else {
                        ribbon = null
                    }
                    return (
                        <Table.Row positive={activePatientId && activePatientId === _id}
                                   onClick={() => onPatientClick(_id)}
                                   key={_id}>
                            <Table.Cell>{ribbon}<span>{surname} {name}</span></Table.Cell>
                            <TableCellEllipsis text={about}/>
                            <Table.Cell style={{textAlign: "center"}}>{num_examinations}</Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{num_symmetries}</Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{num_differences}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </>
    );

}

export const PatientTable = withPagination(PatientTableImpl, 15);