import * as React from "react";
import {Message} from "semantic-ui-react";

export const OperationStatus = {
    Success: 'Success',
    Error: 'Error',
    None: 'None'
}

export class OperationMessage extends React.PureComponent {

    render() {
        const isSuccess = this.props.status === OperationStatus.Success;
        const header = isSuccess ? this.props.header.success : this.props.header.error;
        const content = isSuccess ? this.props.content.success : this.props.content.error;
        return (
            <Message success={this.props.status === OperationStatus.Success}
                     error={this.props.status === OperationStatus.Error}
                     floating
                     visible
                     hidden={this.props.status === OperationStatus.None}
                     onDismiss={this.props.onDismiss}>
                <Message.Header>{header}</Message.Header>
                <p>{content}</p>
            </Message>
        )
    }

}
