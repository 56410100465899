import * as React from "react";
import {Dropdown, Form, Input} from "semantic-ui-react";
import {injectIntl} from "react-intl";


class ConnectionAddressImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: this.props.url,
            port: this.props.port,
            secureConnection: this.props.secureConnection
        };
    }

    updateUrl = (event, data) => {
        this.setState({url: data.value}, () => this.props.onUpdate(this.state));
    }

    updatePort = (event, data) => {
        this.setState({port: Number(data.value)}, () => this.props.onUpdate(this.state));
    }

    updateSecureConnection = (event, data) => {
        this.setState({secureConnection: data.value}, () => this.props.onUpdate(this.state));
    }

    render() {
        const protocols = [
            {key: 'web-socket', text: 'ws://', value: false},
            {key: 'web-socket-secure', text: 'wss://', value: true}
        ];
        const {intl} = this.props;
        return (
            <Form.Field inline>
                <Input label={<Dropdown defaultValue={this.state.secureConnection}
                                        options={protocols}
                                        onChange={this.updateSecureConnection}/>}
                       placeholder={intl.formatMessage({
                           id: 'settings.connection.url',
                           description: 'Placeholder for the address url'
                       })}
                       defaultValue={this.state.url}
                       onChange={this.updateUrl}
                />
                <Input
                    placeholder={intl.formatMessage({
                        id: 'settings.connection.port',
                        description: "Placeholder for address port"
                    })}
                    defaultValue={this.state.port}
                    style={{width: 70}}
                    onChange={this.updatePort}
                />
            </Form.Field>
        );
    }

}

export const ConnectionAddress = injectIntl(ConnectionAddressImpl);
