import {Header, Icon} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const ExaminationListHeader = ({numFilteredExaminations, numAllExaminations}) => {
    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="heartbeat"/>
            <Header.Content>
                <FormattedMessage id="examinations.list.header"
                                  description="The header of examination list view"/>
                <Header.Subheader>
                    <FormattedMessage id="examinations.list.num_records"
                                      description="The number of visible examinations"
                                      values={{
                                          filtered: numFilteredExaminations,
                                          examinations: numAllExaminations
                                      }}/>
                </Header.Subheader>
            </Header.Content>
        </Header>
    );
}