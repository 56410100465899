import React from 'react'
import {FormattedMessage} from "react-intl";
import {Dimmer, DimmerDimmable, Header, Icon, Label, Loader} from "semantic-ui-react";
import {MeshViewer} from "@iniphy/mesh-viewer";
import {useGlobalState} from "../GlobalStateProvider";

export const MeshViewerTranslations = {
    left: <FormattedMessage id="meshviewer.left"/>,
    front: <FormattedMessage id="meshviewer.front"/>,
    right: <FormattedMessage id="meshviewer.right"/>,
    back: <FormattedMessage id="meshviewer.back"/>
};

export const ScanViewer = ({
                               dimmed = false,
                               dimmedMessage = "",
                               dimmedIcon = "calculator",
                               loading = false,
                               activeIndex,
                               symmetryPaneIndex,
                               mesh,
                               distanceMap,
                               label = null,
                               labelColor = "violet",
                               labelAttached = "top left"
                           }) => {

    const [state] = useGlobalState();

    const maximalRange = activeIndex === symmetryPaneIndex
        ? state.settings.maximalRangeOfVisibleChangesOfSymmetry
        : state.settings.maximalRangeOfVisibleChangesOfDifference;
    const minDistance = -maximalRange;
    const maxDistance = maximalRange;

    return (
        <DimmerDimmable blurring
                        dimmed={dimmed}
            // z-index is required, otherwise the dropdown menu will be overshadowed by the dimmer
                        style={{zIndex: 0}}>
            <div>
                {label &&
                    <Label as='a' color={labelColor} attached={labelAttached}>
                        {label}
                    </Label>}
                <span>
                <MeshViewer mesh={mesh}
                            distanceMap={distanceMap}
                            minDistance={minDistance}
                            maxDistance={maxDistance}
                            translation={MeshViewerTranslations}/>
                </span>
            </div>
            <Dimmer active={dimmed}
                    style={{backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
                {loading ? <Loader size="massive">Loading</Loader> : <Header as='h2' icon inverted>
                    <Icon name={dimmedIcon}/>
                    {dimmedMessage}
                </Header>}
            </Dimmer>
        </DimmerDimmable>
    );
}