import * as React from "react";
import {Button, Divider, Form, Icon, Message, Progress} from "semantic-ui-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Database} from "../app/AppDatabase";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import {bySurnameNameSorter} from "../common/Helpers";
import {withGlobalState} from "../GlobalStateProvider";

class DatabaseImportExportImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            processing: false,
            useCompression: false,
            intoChunks: true,
            chunkMaxSizeMB: 256,
            exportCompression: 6,
            exportPercent: null,
            importPercent: null,
            counts: null,
            dumpFilename: null
        };
    }

    handleChecked = (_, {id, checked}) => {
        this.setState({[id]: checked});
    }

    updateExportProgress = (metadata) => {
        this.setState({
            exportPercent: metadata.percent
        });
    }

    bytesToMB = (bytes) => {
        return bytes / 1024 / 1024;
    }

    createZippedChunk = async (zip, timeNow, chunkNum = 1) => {
        const {exportCompression, useCompression} = this.state;
        // Remove 'await' to make the zipping truly concurrent again!
        await zip.generateAsync({
            type: "blob",
            compression: useCompression ? "DEFLATE" : "STORE",
            compressionOptions: {
                level: exportCompression
            },
            streamFiles: true
        }).then((content) => {
            const dumpFilename = `physiotoolkit_db_dump_${timeNow}_chunk_${chunkNum.toString().padStart(3, "0")}.zip`;
            saveAs(content, dumpFilename);
        });
    }

    prepareExportList = (metaData) => {
        // Go over all checkups, symmetries, differences and create a list of stuff to export
        const exportList = [];
        console.log(metaData);
        exportList.push(...metaData.examinations.map(elem => ({id: elem._id, type: "examinations", ext: "ply"})));
        exportList.push(...metaData.symmetries.map(elem => ({id: elem._id, type: "symmetries", ext: "pdm"})));
        exportList.push(...metaData.differences.map(elem => ({id: elem._id, type: "differences", ext: "pdm"})));
        return exportList;
    }

    getBinaryData = async (id, type) => {
        if (type === "examinations") {
            return (await Database.readExamination(id)).mesh;
        } else if (type === "symmetries") {
            return (await Database.readSymmetry(id)).distanceMap;
        } else if (type === "differences") {
            return (await Database.readDifference(id)).distanceMap;
        }
        console.error("Invalid binary data type: ", type);
    }

    exportDatabaseNew = async () => {
        this.setState({processing: true, importPercent: null, exportPercent: 0, counts: null});
        const {chunkMaxSizeMB} = this.state;
        let currentChunkSizeMB = 0.0;
        let currentChunkNum = 1;
        let zip = JSZip();
        const timeNow = new Date().toISOString();
        let currentDir = null;
        let currentDirType = null;
        // First, we export metaData always in the first chunk
        const data = await Database.exportDatabase(false);
        const exportList = this.prepareExportList(data);
        let numExportedSoFar = 0;
        const numItemsForExport = data.patients.length
            + data.examinations.length
            + data.symmetries.length
            + data.differences.length;
        const metaJSON = JSON.stringify(data, null, 2);
        currentChunkSizeMB += this.bytesToMB(metaJSON.length);
        zip.file("meta.json", metaJSON);
        numExportedSoFar += data.patients.length;
        this.setState({exportPercent: numExportedSoFar / numItemsForExport * 100});
        // Then, we prepare all object needed for the valid database export
        for (const {id, type, ext} of exportList) {
            console.log("Current size of chunk", currentChunkSizeMB)
            if (currentDir === null || currentDirType !== type) {
                console.log("Switching to a dir type: ", type);
                currentDir = zip.folder(type);
                currentDirType = type;
            }
            const binary = await this.getBinaryData(id, type);
            const size_mb = this.bytesToMB(binary.byteLength);

            if (currentChunkSizeMB + size_mb > chunkMaxSizeMB) {
                // Time to output the file
                await this.createZippedChunk(zip, timeNow, currentChunkNum);
                zip = JSZip();
                currentDir = zip.folder(type);
                console.log("Chunking at", currentChunkSizeMB);
                currentChunkSizeMB = 0;
                currentChunkNum += 1;
            }
            // Save the binary
            currentDir.file(`${id}.${ext}`, binary, {binary: true});
            currentChunkSizeMB += size_mb;
            numExportedSoFar += 1;
            console.log("numExportedSoFar", numExportedSoFar)
            this.setState({exportPercent: Math.min(numExportedSoFar / numItemsForExport * 100, 95)});
        }
        // Check if something has left to save
        if (currentChunkSizeMB > 0.0) {
            await this.createZippedChunk(zip, timeNow, currentChunkNum);
            console.log("Creating last chunk archive with a single-file inside");
        }
        this.setState({
            processing: false, importPercent: null, exportPercent: 100,
            counts: {
                patients: data.patients.length,
                examinations: data.examinations.length,
                symmetries: data.symmetries.length,
                differences: data.differences.length
            }
        });
    }

    exportDatabase = async () => {
        this.setState({processing: true, importPercent: null, exportPercent: 0, counts: null});
        const {exportCompression, useCompression} = this.state;
        const data = await Database.exportDatabase(false);
        const zip = new JSZip();
        const numPatients = data.patients.length;
        let numExaminations = 0;
        let numSymmetries = 0;
        let numDifferences = 0;
        zip.file("meta.json", JSON.stringify(data, null, 2))
        // Export attachments from examinations
        const examinationsDir = zip.folder("examinations");
        for (const {_id} of data.examinations) {
            const examination = await Database.readExamination(_id);
            examinationsDir.file(`${_id}.ply`, examination.mesh, {binary: true});
            ++numExaminations;
        }
        // Export attachments from symmetries
        const symmetriesDir = zip.folder("symmetries");
        for (const {_id} of data.symmetries) {
            const symmetry = await Database.readSymmetry(_id);
            symmetriesDir.file(`${_id}.pdm`, symmetry.distanceMap, {binary: true});
            ++numSymmetries;
        }
        // Export attachments from differences
        const differencesDir = zip.folder("differences");
        for (const {_id} of data.differences) {
            const difference = await Database.readDifference(_id);
            differencesDir.file(`${_id}.pdm`, difference.distanceMap, {binary: true});
            ++numDifferences;
        }
        zip.generateAsync({
            type: "blob",
            compression: useCompression ? "DEFLATE" : "STORE",
            compressionOptions: {
                level: exportCompression
            },
            streamFiles: true
        }, this.updateExportProgress)
            .then((content) => {
                const timeNow = new Date().toISOString();
                const dumpFilename = `physiotoolkit_db_dump_${timeNow}.zip`;
                saveAs(content, dumpFilename);
                this.setState({
                    counts: {
                        patients: numPatients,
                        examinations: numExaminations,
                        symmetries: numSymmetries,
                        differences: numDifferences
                    },
                    dumpFilename: dumpFilename,
                    processing: false
                });
            });
    }

    _importSingleExamination = async (indexedExaminations, path, file) => {
        const id = this.getIdFromAFilename(path);
        const examination = indexedExaminations[id];
        if (examination == null) {
            console.error("Missing examination in metadata with id", id);
            return;
        }
        const mesh = await file.async("arraybuffer");
        console.log("Importing examination", examination._id);
        await Database.createExamination({
            _id: examination._id,
            patient_id: examination.patient_id,
            name: examination.name,
            note: examination.note,
            date: examination.date,
            mesh: mesh
        });
    }

    _importSingleSymmetry = async (indexedSymmetries, path, file) => {
        const id = this.getIdFromAFilename(path);
        const symmetry = indexedSymmetries[id];
        if (symmetry == null) {
            console.error("Missing symmetry in metadata with id", id);
            return;
        }
        const distanceMap = await file.async("arraybuffer");
        console.log("Importing symmetry", symmetry._id);
        await Database.createSymmetry({
            symmetry: symmetry._id,
            patient_id: symmetry.patient_id,
            examination_id: symmetry.examination_id,
            note: symmetry.note,
            distanceMap: distanceMap
        });
    }

    _importSingleDifference = async (indexedDifferences, path, file) => {
        const id = this.getIdFromAFilename(path);
        const difference = indexedDifferences[id];
        if (difference == null) {
            console.error("Missing difference in metadata with id", id);
            return;
        }
        const distanceMap = await file.async("arraybuffer");
        console.log("Importing difference", difference._id);
        await Database.createDifference({
            _id: difference._id,
            patient_id: difference.patient_id,
            examination_id: difference.examination_id,
            other_examination_id: difference.other_examination_id,
            distanceMap: distanceMap,
            note: difference.note
        });
    }

    getIdFromAFilename = (filename) => {
        return filename.split(".")[0];
    }

    sortFileList = (fileList) => {
        console.log(fileList)
        const files = [];
        // Transform FileList to List[File]; don't use .items(), instead use [] - according to the File API specification,
        // interfaces like FileList will be replaced by Array; https://developer.mozilla.org/en-US/docs/Web/API/FileList
        for (let i = 0; i < fileList.length; ++i) {
            files.push(fileList[i]);
        }
        files.sort((a, b) => a.name.localeCompare(b.name));
        return files;
    }

    toIndexedCollection = (collection, byKey = "_id") => {
        return Object.assign({}, ...collection.map(elem => ({[elem[byKey]]: elem})))
    }

    importDatabaseNew = async (event) => {
        this.setState({processing: true, importPercent: 0, exportPercent: null, counts: null});
        const files = this.sortFileList(event.target.files);
        let metaData = null;
        const importedElements = {
            patients: 0,
            examinations: 0,
            symmetries: 0,
            differences: 0
        };
        for (let num = 0; num < files.length; ++num) {
            let numItemsToImport = 0;
            console.log("Importing chunk:", num + 1);
            const content = await files[num].arrayBuffer();
            const zip = await JSZip().loadAsync(content);
            if (metaData === null) {
                const meta = zip.file("meta.json")?.async("string");
                if (num === 0 && meta === undefined) {
                    console.error("No meta.json in the first chunk archive. Please select the archives in the correct order.")
                    return;
                }
                metaData = JSON.parse(await meta);
                console.log("Meta data", metaData);
                importedElements.patients += metaData.patients.length;
                importedElements.examinations += metaData.examinations.length;
                importedElements.symmetries += metaData.symmetries.length;
                importedElements.differences += metaData.differences.length;
                await Database.importPatients(metaData.patients);
            }
            const indexedExaminations = this.toIndexedCollection(metaData.examinations);
            let promises = [];
            zip.folder("examinations")?.forEach((path, file) => {
                promises.push(this._importSingleExamination(indexedExaminations, path, file));
            });
            await Promise.allSettled(promises);
            promises = [];
            const indexedSymmetries = this.toIndexedCollection(metaData.symmetries);
            zip.folder("symmetries")?.forEach((path, file) => {
                promises.push(this._importSingleSymmetry(indexedSymmetries, path, file));
            });
            await Promise.allSettled(promises);
            promises = [];
            const indexedDifferences = this.toIndexedCollection(metaData.differences);
            zip.folder("differences")?.forEach((path, file) => {
                promises.push(this._importSingleDifference(indexedDifferences, path, file));
            });
            await Promise.allSettled(promises);
            const newPatients = await Database.listPatients(true);
            this.props.dispatch({
                patients: newPatients.sort(bySurnameNameSorter)
            });
            this.setState({importPercent: Math.min((num + 1) * (100 / files.length), 95)});
        }
        this.setState({
            counts: importedElements,
            processing: false,
            importPercent: 100
        });
    }

    importDatabase = async (event) => {
        this.setState({processing: true, importPercent: 0, exportPercent: null, counts: null});
        const content = await event.target.files[0].arrayBuffer();
        const zip = await JSZip().loadAsync(content);
        let numPatients = 0;
        let numExaminations = 0;
        let numSymmetries = 0;
        let numDifferences = 0;
        // Load metadata
        const meta = zip.file("meta.json")?.async("string");
        if (meta === undefined) {
            console.error("There is no meta.json in the imported database. Aborting.")
            return;
        }
        // Import metadata
        let metaData = JSON.parse(await meta);
        console.log("Meta data", metaData);
        await Database.importPatients(metaData.patients);
        numPatients = metaData.patients.length;
        this.setState({importPercent: 10});
        // Import examinations
        for (const {_id, patient_id, name, note, date} of metaData.examinations) {
            const file = zip.folder("examinations").file(`${_id}.ply`);
            if (file) {
                const mesh = await file.async("arraybuffer");
                console.log("Importing examination", _id);
                await Database.createExamination({_id, patient_id, name, note, date, mesh});
                ++numExaminations;
            } else {
                console.error(`Missing examination ${_id} in the database dump.`);
            }
        }
        this.setState({importPercent: 70});
        // Import symmetries
        for (const {_id, patient_id, examination_id, note} of metaData.symmetries) {
            const file = zip.folder("symmetries").file(`${_id}.pdm`);
            if (file) {
                const distanceMap = await file.async("arraybuffer");
                console.log("Importing symmetry", _id);
                await Database.createSymmetry({_id, patient_id, examination_id, note, distanceMap});
                ++numSymmetries;
            } else {
                console.error(`Missing symmetry ${_id} in the database dump.`);
            }
        }
        this.setState({importPercent: 85});
        // Import differences
        for (const {_id, patient_id, examination_id, other_examination_id, note} of metaData.differences) {
            const file = zip.folder("differences").file(`${_id}.pdm`);
            if (file) {
                const distanceMap = await file.async("arraybuffer");
                console.log("Importing difference", _id);
                await Database.createDifference({
                    _id,
                    patient_id,
                    examination_id,
                    other_examination_id,
                    distanceMap,
                    note
                });
                ++numDifferences;
            } else {
                console.error(`Missing difference ${_id} in the database dump.`);
            }
        }
        this.setState({
            counts: {
                patients: numPatients,
                examinations: numExaminations,
                symmetries: numSymmetries,
                differences: numDifferences
            },
            processing: false,
            importPercent: 100
        });
        // Load imported patients
        const newPatients = await Database.listPatients(true);
        this.props.dispatch({
            patients: newPatients.sort(bySurnameNameSorter)
        });
    }

    render() {
        const {intl} = this.props;
        const {
            exportCompression,
            exportPercent,
            importPercent,
            counts,
            dumpFilename,
            processing,
            useCompression,
            intoChunks,
            chunkMaxSizeMB
        } = this.state;
        return (
            <>
                {exportPercent === 100.00 && counts &&
                    <Message positive icon compact>
                        <Icon name="thumbs up outline"/>
                        <Message.Content>
                            <Message.Header>
                                <FormattedMessage
                                    id="settings.database.export.success.header"
                                    description="Header of the successful export message"
                                />
                            </Message.Header>
                            <FormattedMessage
                                id="settings.database.export.success.msg"
                                description="Summary of exported dump"
                                values={{
                                    dumpFilename: <b>{dumpFilename}</b>,
                                    patients: counts.patients,
                                    examinations: counts.examinations,
                                    symmetries: counts.symmetries,
                                    differences: counts.differences
                                }}
                            />
                        </Message.Content>
                    </Message>}
                {exportPercent !== null &&
                    <Progress progress="percent" percent={Math.round(exportPercent)} indicating>
                        <FormattedMessage
                            id="settings.database.export.progress"
                            description="Processing database export"
                        />
                    </Progress>}
                <Form>
                    <Form.Checkbox
                        toggle
                        id="useCompression"
                        label={intl.formatMessage({
                            id: "settings.database.export.compression.use",
                            description: "Use the compression"
                        })}
                        checked={useCompression}
                        onChange={this.handleChecked}
                    />
                    {useCompression &&
                        <Form.Input
                            disabled={processing}
                            label={intl.formatMessage({
                                id: "settings.database.export.compression.level",
                                description: "Level of the compression"
                            }, {level: exportCompression})}
                            min={1}
                            max={9}
                            name="hide"
                            step={1}
                            type="range"
                            value={exportCompression}
                            onChange={(_, {value}) => this.setState({exportCompression: value})}
                        />}
                    <Form.Checkbox
                        toggle
                        id="intoChunks"
                        label={"Divide into chunks"}
                        checked={intoChunks}
                        onChange={this.handleChecked}
                    />
                    {intoChunks &&
                        <Form.Input
                            label="Maximal chunk size in megabytes [MB]"
                            type="number"
                            min={10}
                            max={4096}
                            value={chunkMaxSizeMB}
                            onChange={(_, {value}) => this.setState({chunkMaxSizeMB: value})}
                        />
                    }
                    <Form.Field>
                        <Button
                            disabled={processing}
                            as="label"
                            icon
                            labelPosition="left"
                            onClick={this.exportDatabaseNew}>
                            <Icon name="download"/>
                            <FormattedMessage
                                id="settings.database.export"
                                description="Export database"
                            />
                        </Button>
                    </Form.Field>
                    <Divider/>
                    {importPercent === 100.00 && counts &&
                        <Message positive icon>
                            <Icon name="thumbs up outline"/>
                            <Message.Content>
                                <Message.Header>
                                    <FormattedMessage
                                        id="settings.database.import.success.header"
                                        description="Header of the successful import message"
                                    />
                                </Message.Header>
                                <FormattedMessage
                                    id="settings.database.import.success.msg"
                                    description="Summary of the import dump"
                                    values={{
                                        patients: counts.patients,
                                        examinations: counts.examinations,
                                        symmetries: counts.symmetries,
                                        differences: counts.differences
                                    }}
                                />
                            </Message.Content>
                        </Message>}
                    {importPercent !== null &&
                        <Progress progress="percent" percent={Math.round(importPercent)} indicating>
                            <FormattedMessage
                                id="settings.database.import.progress"
                                description="Processing database import"
                            />
                        </Progress>}
                    <Form.Field>
                        <Button
                            disabled={processing}
                            as="label"
                            icon
                            htmlFor="importDatabase"
                            labelPosition="left">
                            <Icon name="upload"/>
                            <FormattedMessage
                                id="settings.database.import"
                                description="Import database"
                            />
                        </Button>
                        <input hidden
                               type="file"
                               id="importDatabase"
                               multiple
                               onChange={this.importDatabaseNew}/>
                    </Form.Field>
                </Form>
            </>
        )
    }

}

export const DatabaseImportExport = injectIntl(withGlobalState(DatabaseImportExportImpl));
