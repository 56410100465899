import {Grid} from "semantic-ui-react";
import {ScanViewer} from "../common/ScanViewer";
import React, {useState} from "react";
import {useGlobalState} from "../GlobalStateProvider";
import {newScanningId, ScannerController} from "@iniphy/scanner-controller";
import {ServerErrors} from "../common/ServerErrors";

export const ScannerTestingView = () => {

    const [mesh, setMesh] = useState(undefined);
    const [meshHistory, setMeshHistory] = useState([]);
    const [infinite, setInfinite] = useState(false);
    const [errors, setErrors] = useState([]);
    const [state, dispatch] = useGlobalState();

    const performScanning = async () => {
        const settings = state.settings;
        try {
            const controller = new ScannerController(settings.url, settings.port, settings.secureConnection);
            const examinationId = newScanningId();
            const mesh = await controller.scan(examinationId);
            setMesh(mesh);
        } catch (err) {
            setErrors(err);
        }
    }

    return (
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column>
                    {/*<ScanningForm*/}
                    {/*    onSave={this.saveScanningResults}*/}
                    {/*    saveDisabled={mesh === undefined || scanning || analysingSymmetry}*/}
                    {/*/>*/}
                    {/*<ScanningProgress*/}
                    {/*    scanning={scanning}*/}
                    {/*    analysingSymmetry={analysingSymmetry}*/}
                    {/*/>*/}
                    <ServerErrors errors={errors}/>
                </Grid.Column>
                <Grid.Column>
                    <ScanViewer
                        mesh={mesh}
                        // dimmed={!mesh}
                        // dimmedIcon="street view"
                        // dimmedMessage={<FormattedMessage id='scanning.start_scan'
                        //                                  description='Start scanning'/>}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

}