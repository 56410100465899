import {Grid} from "semantic-ui-react";
import React from "react";


export const DetailsLayout = ({details, scanViewer}) => {

    return (<Grid columns={2}>
            <Grid.Row>
                <Grid.Column>
                    {details}
                </Grid.Column>
                <Grid.Column>
                    {scanViewer}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};