import React from "react";
import {Button, Header, Icon, List, Modal} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";


export const PatientRemoveConfirmation = ({open, patient, onCancel, onRemove}) => {

    const intl = useIntl();

    return (
        <Modal open={open} size="tiny">
            <Modal.Header>
                <Icon name="warning sign"/>
                <FormattedMessage
                    id="patients.confirmation.header"
                    description="Remove patient window header"
                />
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as="h4">
                        <FormattedMessage
                            id="patients.confirmation.are_you_sure"
                            description="Are you sure about removing the patient?"
                            values={{name: patient.name, surname: patient.surname}}/>
                    </Header>
                    <FormattedMessage
                        id="patients.confirmation.details"
                        description="You will also remove"
                    />
                    <List as="ul">
                        <List.Item as="li">
                            <FormattedMessage id="patients.confirmation.n_examinations"
                                              values={{number: patient.num_examinations}}/>
                        </List.Item>
                        <List.Item as="li">
                            <FormattedMessage id="patients.confirmation.n_symmetries"
                                              values={{number: patient.num_symmetries}}/>
                        </List.Item>
                        <List.Item as="li">
                            <FormattedMessage id="patients.confirmation.n_differences"
                                              values={{number: patient.num_differences}}/>
                        </List.Item>
                    </List>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={intl.formatMessage({
                        id: "patients.confirmation.cancel",
                        description: "Cancel the removal"
                    })}
                    labelPosition="left"
                    icon="close"
                    onClick={onCancel}
                />
                <Button
                    negative
                    content={intl.formatMessage({
                        id: "patients.confirmation.remove",
                        description: "Finish the removal"
                    })}
                    labelPosition="left"
                    icon='trash'
                    onClick={() => onRemove(patient._id)}
                />
            </Modal.Actions>
        </Modal>
    )
}
