import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import React from "react";

export const PatientMissing = ({onAddNewPatient}) => {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name="user md"/>
                <FormattedMessage
                    id="patients.list.no_patients"
                    description="There are no patients registered"/>
            </Header>
            <Button icon
                    labelPosition="left"
                    primary
                    onClick={onAddNewPatient}>
                <Icon name="add user"/>
                <FormattedMessage
                    id="patients.list.add_new"
                    description="Add new patient"/>
            </Button>
        </Segment>
    );
}
