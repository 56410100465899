import React, {useEffect} from 'react';
import {Database} from "../../app/AppDatabase";
import {useGlobalState} from "../../GlobalStateProvider";
import {checkIsServerOnline, formatScanDatetime, updatePatientNumsBy} from "../../common/Helpers";
import {ScannerController} from "@iniphy/scanner-controller";
import {MeshProcessing} from "@iniphy/mesh-processing";
import {Details} from "./Details";
import {DetailsLayout} from "../DetailsLayout";
import {ScanViewer} from "../../common/ScanViewer";
import {useIntl} from "react-intl";
import {DateFnsLocale} from "../../app/Parameters";

export const View = ({examination, symmetry}) => {

    const intl = useIntl();
    const [state, dispatch] = useGlobalState();

    const removeSymmetry = () => {
        Database.deleteSymmetry(symmetry._id).then(() => {
            dispatch({
                patients: updatePatientNumsBy(state.patients, examination.patient_id, 0, -1, 0),
                symmetries: state.symmetries.filter(e => e._id !== symmetry._id)
            });
        });
    }

    const computeSymmetry = async () => {
        const settings = state.settings;
        dispatch({computing: true});
        try {
            let distanceMap;
            const useScannerForAnalysis = settings.performAnalysisOnScanner && await checkIsServerOnline(settings.url, settings.port);
            if (useScannerForAnalysis) {
                const controller = new ScannerController(settings.url, settings.port, settings.secureConnection, true);
                const hasScan = await controller.hasScan(examination._id);
                if (!hasScan) {
                    await controller.uploadScan(examination._id, examination.mesh);
                }
                distanceMap = await controller.analyseSymmetry(examination._id, settings.symmetryReflectionAxis);
            } else {
                const meshProcessing = new MeshProcessing();
                distanceMap = await meshProcessing.symmetryAnalysis(examination.mesh, settings.symmetryReflectionAxis);
            }
            const symmetry = {
                patient_id: examination.patient_id,
                examination_id: examination._id,
                note: "",
                distanceMap: distanceMap
            };
            const newSymmetry = await Database.createSymmetry(symmetry);
            const updatedSymmetries = state.symmetries;
            updatedSymmetries.push(newSymmetry);
            dispatch({
                patients: updatePatientNumsBy(state.patients, examination.patient_id, 0, 1, 0),
                symmetries: updatedSymmetries,
                computing: false
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        async function loadMissingDistanceMesh() {
            // Read the scan mesh if not available
            if (!examination.mesh) {
                const fullExamination = await Database.readExamination(examination._id);
                const updatedExaminations = state.examinations.map(entry => entry._id === examination._id ? fullExamination : entry)
                dispatch({examinations: updatedExaminations})
            }
            // Read symmetry distance map if not available
            if (symmetry && !symmetry.distanceMap) {
                const fullSymmetry = await Database.readSymmetry(symmetry._id);
                const updatedSymmetries = state.symmetries.map(e => e._id === symmetry._id ? fullSymmetry : e)
                dispatch({symmetries: updatedSymmetries});
            }
        }

        loadMissingDistanceMesh().then();
    }, [examination, symmetry, state, dispatch]);

    // TODO: add placeholder when the data is still loading
    return <DetailsLayout
        details={<Details examination={examination}
                          symmetry={symmetry}
                          onComputeSymmetry={computeSymmetry}
                          onRemoveSymmetry={removeSymmetry}/>}
        // TODO: move min/max Distance as parameters here
        scanViewer={<ScanViewer dimmed={!symmetry}
                                dimmedMessage={intl.formatMessage({
                                    id: "examinations.details.compute_symmetry"
                                })}
                                dimmedIcon="calculator"
                                mesh={examination.mesh}
                                distanceMap={symmetry ? symmetry.distanceMap : undefined}
                                label={symmetry ? formatScanDatetime(examination.date, DateFnsLocale[state.settings.language]) : null}/>}
    />

}
