import {Details as ScanDetails} from "../scan/Details";
import {Button} from "semantic-ui-react";
import React from "react";
import {useIntl} from "react-intl";

export const Details = ({examination, symmetry, onComputeSymmetry, onRemoveSymmetry}) => {

    const intl = useIntl()

    return (
        <>
            <ScanDetails examination={examination}/>
            {symmetry &&
                <Button
                    negative
                    content={intl.formatMessage({
                        id: "examinations.overview.remove",
                        description: "Remove symmetry"
                    })}
                    icon="trash"
                    labelPosition="left"
                    floated="right"
                    onClick={onRemoveSymmetry}
                />}
            {!symmetry &&
                <Button
                    positive
                    content={intl.formatMessage({
                        id: "examinations.overview.compute",
                        description: "Calculate symmetry"
                    })}
                    icon="calculator"
                    labelPosition="left"
                    onClick={onComputeSymmetry}
                />}
        </>
    );

}