import {Pagination, Table} from "semantic-ui-react";
import React, {useState} from "react";
import {paginateArray} from "./Helpers";

export const withPagination = (Component, paginationSize) => {

    return function WrappedComponent(props) {
        const {items} = props;
        const [activePage, setActivePage] = useState(1);

        const totalPages = Math.ceil(items.length / paginationSize);
        const paginatedItems = paginateArray(items, activePage, paginationSize);

        return (
            <Table singleLine selectable>
                <Component {...props} items={paginatedItems}/>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan={"100%"}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Pagination
                                    boundaryRange={0}
                                    defaultActivePage={1}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={1}
                                    totalPages={Math.max(totalPages, 1)}
                                    disabled={totalPages <= 1}
                                    onPageChange={(e, {activePage}) => setActivePage(Number(activePage))}
                                />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }

}
