import {saveAs} from 'file-saver';
import {differenceInYears, format, parse} from "date-fns";
import {enGB} from "date-fns/locale";

export const byDateSorter = (a, b) => new Date(b.date) - new Date(a.date);

export const bySurnameNameSorter = (a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name);

export const downloadAsBinaryFile = (filename, data) => {
    const blob = new Blob([data], {type: "application/octet-stream"})
    saveAs(blob, filename);
}

export const downloadAsJsonFile = (filename, data) => {
    const blob = new Blob([data], {type: "application/json;charset=utf-8"})
    saveAs(blob, filename);
}

export const combineDateAndTime = (date, time) => {
    // @pna: https://stackoverflow.com/a/8424025/1319478
    return new Date(
        date.getFullYear(), date.getMonth(), date.getDate(),
        time.getHours(), time.getMinutes(), time.getSeconds());
}

export const paginateArray = (data, activePage, paginationSize) => {
    // If pagination is necessary
    if (paginationSize < data.length) {
        const startExamination = (activePage - 1) * paginationSize;
        const endExamination = Math.min((activePage - 1) * paginationSize + paginationSize, data.length)
        return data.slice(startExamination, endExamination);
    }
    // Otherwise, returns the input data unchanged
    return data;
}

export const checkIsServerOnline = async (url, port, timeout = 1000) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const options = {mode: 'no-cors', signal};
    try {
        await fetch(`http://${url}:${port}`, options)
            .then(setTimeout(() => controller.abort(), timeout));
        return true;
    } catch (err) {
        return false;
    }
}

export const updatePatientNumsBy = (patients, patientId, numExaminations, numSymmetries, numDifferences) => {
    return patients.map(entry => {
        if (entry._id === patientId) {
            entry.num_examinations += numExaminations;
            entry.num_symmetries += numSymmetries;
            entry.num_differences += numDifferences;
        }
        return entry;
    })
}

export const formatScanDatetime = (date, locale = enGB) => {
    return format(new Date(date), "PPPPpp", {locale: locale});
}

export const calculateAge = (birthday) => {
    let date;
    // TODO: this is only a quick fixed. Unify the use of ISO string/date object in the application
    if (birthday instanceof Date) {
        date = birthday;
    } else {
        date = parse(birthday.substring(0, 10),"yyyy-MM-dd", new Date());
    }
    return differenceInYears(new Date(), date);
}
