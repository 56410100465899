import React from 'react';
import {Button, Grid, Icon} from "semantic-ui-react";
import {newScanningId, ScannerController} from "@iniphy/scanner-controller";
import {ServerErrors} from "../common/ServerErrors";
import {FormattedMessage} from "react-intl";
import {Database} from "../app/AppDatabase";
import {MeshProcessing} from "@iniphy/mesh-processing";
import {NavigationView, withGlobalState} from "../GlobalStateProvider";
import {byDateSorter, updatePatientNumsBy} from "../common/Helpers";
import {ScanningForm} from "./details/ScanningForm";
import {ScanningProgress} from "./details/ScanningProgress";
import {ScanningHeader} from "./details/ScanningHeader";
import {ScanViewer} from "../common/ScanViewer";

class ScanningImpl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            scanning: false,
            analysingSymmetry: false,
            currentExaminationId: null,
            mesh: undefined,
            distanceMap: undefined,
            timeStartScan: null
        }
    }

    componentDidMount() {
        const {state, startOnNewExamination} = this.props;
        if (state.settings.startScanningOnNewExamination && startOnNewExamination) {
            this.onStartScan();
        }
    }

    onStartScan = async () => {
        const {state, dispatch} = this.props;
        const settings = state.settings;
        const examinationId = newScanningId();
        dispatch({computing: true});
        this.setState({
            scanning: true,
            errors: [],
            currentExaminationId: examinationId,
            timeStartScan: new Date(),
            mesh: undefined,
            distanceMap: undefined
        });
        const controller = new ScannerController(settings.url, settings.port, settings.secureConnection);
        try {
            const mesh = await controller.scan(examinationId);
            this.setState({mesh: mesh});
            let distanceMap = undefined;
            if (settings.automaticSymmetryAnalysis) {
                this.setState({
                    scanning: false,
                    analysingSymmetry: true
                });
                // There is no need for a fallback offline analysis, because we have just performed the scanning.
                // We assume it is still online.
                if (settings.performAnalysisOnScanner) {
                    distanceMap = await controller.analyseSymmetry(examinationId, settings.symmetryReflectionAxis);
                } else {
                    const meshProcessing = new MeshProcessing();
                    distanceMap = await meshProcessing.symmetryAnalysis(mesh, settings.symmetryReflectionAxis);
                }
            }
            this.setState({
                scanning: false,
                analysingSymmetry: false,
                mesh: mesh,
                distanceMap: distanceMap
            });
            dispatch({computing: false});
        } catch (err) {
            // TODO: make sure this never happens
            if (err.length === 0) {
                err.push({code: 0, msg: "Unknown error type"})
            }
            this.setState({
                scanning: false,
                errors: err
            });
            dispatch({computing: false});
        }
    }

    saveScanningResults = async (name, note) => {
        const {state, dispatch} = this.props;
        const {examinationId, mesh, distanceMap, timeStartScan} = this.state;

        const patientScan = {
            _id: examinationId,
            patient_id: state.activePatientId,
            name: name,
            note: note,
            date: timeStartScan,
            mesh: mesh
        };
        const newExamination = await Database.createExamination(patientScan);
        // Save the result if the symmetry analysis was performed
        let newSymmetry = null
        if (distanceMap) {
            const symmetry = {
                patient_id: state.activePatientId,
                examination_id: newExamination._id,
                note: "",
                distanceMap: distanceMap
            };
            newSymmetry = await Database.createSymmetry(symmetry);
        }
        this.setState({
            scanning: false,
            analysingSymmetry: false
        });
        if (distanceMap && newExamination && newSymmetry) {
            const updatedExaminations = state.examinations;
            updatedExaminations.push(newExamination);
            const updatedSymmetries = state.symmetries;
            updatedSymmetries.push(newSymmetry);
            dispatch({
                computing: false,
                activeView: NavigationView.EXAMINATION_DETAIL,
                examinations: updatedExaminations.sort(byDateSorter),
                symmetries: updatedSymmetries,
                activeExaminationId: newExamination._id,
                patients: updatePatientNumsBy(state.patients, state.activePatientId, 1, 1, 0)
            });
        } else {
            const updatedExaminations = state.examinations;
            updatedExaminations.push(newExamination);
            dispatch({
                computing: false,
                activeView: NavigationView.EXAMINATION_DETAIL,
                examinations: updatedExaminations.sort(byDateSorter),
                activeExaminationId: newExamination._id,
                patients: updatePatientNumsBy(state.patients, state.activePatientId, 1, 0, 0)
            });
        }
    }

    render() {
        const {scanning, analysingSymmetry, errors, mesh, distanceMap} = this.state;
        return (
            <>
                <Button
                    icon
                    labelPosition='left'
                    floated="right"
                    onClick={this.onStartScan}
                    color='green'
                    disabled={scanning || analysingSymmetry}>
                    <Icon name='street view'/>
                    {!mesh
                        ? <FormattedMessage id='scanning.start_scan'
                                            description='Start scanning'/>
                        : <FormattedMessage id='scanning.redo_scan'
                                            description='Redo again scanning'/>}
                </Button>
                <ScanningHeader/>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <ScanningForm
                                onSave={this.saveScanningResults}
                                saveDisabled={mesh === undefined || scanning || analysingSymmetry}
                            />
                            <ScanningProgress
                                scanning={scanning}
                                analysingSymmetry={analysingSymmetry}
                            />
                            <ServerErrors errors={errors}/>
                        </Grid.Column>
                        <Grid.Column>
                            <ScanViewer
                                mesh={mesh}
                                distanceMap={distanceMap}
                                dimmed={!mesh}
                                dimmedIcon="street view"
                                dimmedMessage={<FormattedMessage id='scanning.start_scan'
                                                                 description='Start scanning'/>}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
}

export const ScanningView = withGlobalState(ScanningImpl);