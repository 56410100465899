import {withPagination} from "../../common/withPagination";
import {Icon, Table} from "semantic-ui-react";
import {LocalSearchInput} from "../../sidebar/LocalSearchInput";
import {FormattedMessage} from "react-intl";
import {format} from "date-fns";
import {TableCellEllipsis} from "../../common/TableCellEllipsis";
import React from "react";
import {TableRowRibbon} from "../../common/TableRowRibbon";

const ExaminationTableImpl = ({
                                  onExaminationClick,
                                  symmetries,
                                  differences,
                                  // items prop is passed from the withPagination() component
                                  items: paginatedExaminations,
                                  onSearchQueryChange
                              }) => {

    let currentDate = '';
    let ribbon = null;

    return (
        <>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                        <LocalSearchInput onSearchQueryChange={onSearchQueryChange}
                                          noResults={paginatedExaminations.length === 0}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <Icon name="write"/>
                        <FormattedMessage id="examinations.list.note"
                                          description="Examination note"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="adjust"/>
                        <FormattedMessage id="examinations.list.symmetry"
                                          description="Symmetry of an examination"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="clone outline"/>
                        <FormattedMessage id="examinations.list.differences"
                                          description="Differences of an examination"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="clock outline"/>
                        <FormattedMessage id="examinations.list.time"
                                          description="Time of an examination"/>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {paginatedExaminations.map(({_id, name, date, note}) => {
                    const formattedDate = format(new Date(date), "dd/MM/yyyy");
                    const formattedTime = format(new Date(date), "HH:mm");
                    if (currentDate !== formattedDate) {
                        currentDate = formattedDate
                        ribbon = <TableRowRibbon text={formattedDate}/>
                    } else {
                        ribbon = null
                    }
                    const hasSymmetry = symmetries.some(symmetry => symmetry.examination_id === _id);
                    const numDifferences = differences.filter(difference => difference.examination_id === _id).length;
                    return (
                        <Table.Row key={_id}
                                   onClick={() => onExaminationClick(_id)}>
                            <Table.Cell style={{width: "1px"}}>{ribbon}</Table.Cell>
                            <TableCellEllipsis text={name}/>
                            <TableCellEllipsis text={note}/>
                            <Table.Cell style={{textAlign: "center"}}>{hasSymmetry ?
                                <Icon name="check" color="green"/> :
                                <Icon name="close" color="red"/>}</Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{numDifferences}</Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{formattedTime}</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </>
    );
}

export const ExaminationTable = withPagination(ExaminationTableImpl, 15);
