import {enGB, enUS, fr, pl} from "date-fns/locale"


export const ConnectionAliveInterval = {
    Seconds10: 10 * 1000,
    Seconds30: 30 * 1000,
    Minute1: 60 * 1000,
    Minutes5: 5 * 60 * 1000
};

export const SymmetryReflectionAxis = {
    AUTO: "auto",
    X: "x",
    Y: "y",
    Z: "z"
};

export const DefaultSettings = {
    language: 'default',
    url: 'vps.iniphy.eu',
    port: 8080,
    secureConnection: true,
    connectionAliveInterval: ConnectionAliveInterval.Minute1,
    symmetryReflectionAxis: SymmetryReflectionAxis.Z,
    automaticSymmetryAnalysis: true,
    symmetryIsPrimary: true,
    startScanningOnNewExamination: false,
    performAnalysisOnScanner: false,
    maximalRangeOfVisibleChangesOfSymmetry: 15,
    maximalRangeOfVisibleChangesOfDifference: 15
};

// Required for the react-semantic-ui-datepicker which only uses full language tags (primary tag + one subtag)
// More about the standards and language tags here: https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.10
// > ISO-639 defines the primary tags
// > ISO-3166 defines the first subtag
export const LanguageTags = {
    "pl": "pl-PL",
    "fr": "fr-FR",
    "en": "en-US",
    "en-US": "en-US",
    "en-GB": "en-US" // the datepicker supports only en-US for the moment. Shouldn't be a problem!
}

export const DateFnsLocale = {
    "pl": pl,
    "fr": fr,
    "en": enGB,
    "en-US": enUS,
    "en-GB": enGB,
}

export function selectLocaleForDatepicker(settings) {
    const fallbackLocale = "en-US";
    if (!settings || !settings.language) return fallbackLocale;
    if (settings.language in LanguageTags) {
        return LanguageTags[settings.language];
    }
    return fallbackLocale;
}