import {Message} from "semantic-ui-react";
import * as React from "react";

export const ServerErrors = ({errors}) => {

    const connectionError = errors.length > 0;
    return (<>
        {connectionError && <Message negative>
            <Message.Header>Server error</Message.Header>
            <Message.List>
                {errors.map(({code, msg}, index) => {
                    return <Message.Item
                        key={`msg-${index}`}>{msg} {code !== -1 ? `(code: ${code})` : ''}</Message.Item>
                })}
            </Message.List>
        </Message>}
    </>);
}
