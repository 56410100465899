import {withPagination} from "../../../common/withPagination";
import {Icon, Label, Table} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";
import {format, formatDistance} from "date-fns";
import {TableCellEllipsis} from "../../../common/TableCellEllipsis";
import React, {useState} from "react";

const DifferenceTableImpl = ({items: paginatedDifferences, dateFnsLocale, onSelectDifference}) => {

    const intl = useIntl();
    const firstDifference = paginatedDifferences.length > 0 ? paginatedDifferences[0]._id : null;
    const [selectedDifference, setSelectedDifference] = useState(firstDifference);

    let currentDate = "";
    let ribbon = null;

    return (
        <>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{width: "115px"}}>
                        <Icon name="calendar outline"/>
                        <FormattedMessage id="examinations.difference.date"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id="examinations.difference.name"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="clock outline"/>
                        <FormattedMessage id="examinations.difference.time"/>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}> </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "1px"}}>
                        <Icon name="hourglass half"/>
                        <FormattedMessage id="examinations.difference.time_difference"/>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {paginatedDifferences.map(({_id, name, date, base_date, valid}) => {
                    const inTheFuture = (new Date(date) - new Date(base_date)) > 0;
                    let formattedDate, formattedTime = "--", timeDistance = "--";
                    // Check if the other examination still exists
                    if (valid) {
                        formattedDate = format(new Date(date), "dd/MM/yyyy");
                        formattedTime = format(new Date(date), "HH:mm");
                        timeDistance = formatDistance(
                            new Date(date),
                            new Date(base_date),
                            {locale: dateFnsLocale, addSuffix: true});
                        if (currentDate !== formattedDate) {
                            currentDate = formattedDate
                            ribbon = <Label ribbon>{formattedDate}</Label>
                        }
                    } else {
                        const unknownDate = intl.formatMessage({id: "examinations.difference.date_unknown"});
                        name = intl.formatMessage({id: "examinations.difference.removed_examination"});
                        if (currentDate !== unknownDate) {
                            ribbon = <Label ribbon>{unknownDate}</Label>
                        }
                        currentDate = unknownDate;
                    }
                    return (
                        <Table.Row active={selectedDifference === _id}
                                   key={_id}
                                   negative={!valid}
                                   onClick={() => {
                                       setSelectedDifference(_id);
                                       // setActiveDifference(_id);
                                       onSelectDifference(_id);
                                   }}>
                            <Table.Cell>{ribbon}</Table.Cell>
                            <TableCellEllipsis text={name}/>
                            <Table.Cell style={{textAlign: "center"}}>{formattedTime}</Table.Cell>
                            <Table.Cell>
                                {valid
                                    ? (inTheFuture
                                        ? <Icon name="angle double up" color="green"/>
                                        : <Icon name="angle double down" color="red"/>)
                                    : <Icon name="dont"/>
                                }
                            </Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>{timeDistance}</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </>
    );

}

export const DifferenceTable = withPagination(DifferenceTableImpl, 10);
