import {Input} from "semantic-ui-react";
import React, {useState} from "react";
import {useIntl} from "react-intl";

export const LocalSearchInput = ({onSearchQueryChange = () => {}, noResults = false}) => {

    const intl = useIntl();
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <Input
            error={noResults}
            // style={{marginTop: "-3px", marginBottom: "-3px"}}
            fluid
            value={searchQuery}
            icon='search'
            onChange={(e, {value}) => {
                setSearchQuery(value);
                onSearchQueryChange(value);
            }}
            placeholder={intl.formatMessage({id: "navigation.search"})}
        />
    )
}