import React from "react";
import {Form} from "semantic-ui-react";
import {format, parseISO} from "date-fns";


export const TimeInput = (props) => {

    const updateDatetime = ({target: {value}}) => {
        const currentDate = format(new Date(props.value), "yyyy-MM-dd");
        // TODO: improve this line!
        const updatedDateTime = parseISO(currentDate + " " + value);
        props.onChange(updatedDateTime);
    }

    const currentTime = props.value ? format(new Date(props.value), "HH:mm:ss") : "";

    return (<Form.Input type="time"
                        step="1"
                        {...props}
                        value={currentTime}
                        onChange={updateDatetime}/>);
}
