import {Header, Icon} from "semantic-ui-react";
import * as React from "react";
import {FormattedMessage} from "react-intl";

export const PatientDetailsHeader = ({patient}) => {

    return (
        <Header as="h2" style={{marginTop: "0px"}}>
            <Icon name="user"/>
            <Header.Content>
                <FormattedMessage id={"patients.details.patient"}/>
                <Header.Subheader>
                    {`${patient.name} ${patient.surname}`}
                </Header.Subheader>
            </Header.Content>
        </Header>
    );
}